@import "styles/colours.scss";

.contentGridCore {
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  padding-top: 8px;
  grid-row-gap: 8px;
  grid-column-gap: 4px;
  align-items: flex-start;
}

.extraContent {
  composes: contentGridCore;
  grid-template-columns: auto auto auto auto auto;
}

.buyerDetails {
  composes: contentGridCore;
  grid-template-columns:
    minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto)
    minmax(min-content, auto) max-content;
  grid-gap: 12px;
}

.panel {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;

  .panelHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .countLabel {
    margin: 0 0 0 auto;
    font-size: 16px;
  }

  .listContainer {
    background-color: $app-bg-grey;
    padding: 16px;
    flex: 1 1 auto;
    overflow: auto;
  }

  ul {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    height: 100%;
  }
}

.buyerItem {
  background-color: $white;
  padding: 16px;
  list-style: none;
  display: grid;
  grid-template-columns: auto min-content;
  margin-bottom: 8px;
  grid-gap: 8px;

  h2 {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 16px;
    color: $dark-grey;
  }

  .readMore {
    font-size: 12px;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .secondRow {
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .thirdRow {
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .arrowIcon {
    margin-left: 4px;
    vertical-align: 0;
  }

  .lastBox {
    align-items: flex-end;
  }

  .infoBox {
    display: flex;
    flex-direction: column;

    h3 {
      color: $grey;
      font-size: 12px;
    }

    a {
      font-size: 12px;
      // this stops the a tag from going full width ironically
      // and therefore centers the tooltip over the actual link
      width: max-content;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      color: $dark-grey;
    }
  }

  .urlBox {
    display: grid;
    white-space: nowrap;
  }

  .countBullet {
    color: $white;
    background-color: $green;
    border-radius: 10px;
    width: max-content;
    padding: 2px 8px;
    justify-self: self-end;
    font-weight: 700;
    font-size: 12px;
    // this is to make sure it stays in place if the list doesn't have a cta
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .additionalCTAsContainer {
    @extend .secondRow;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .selectButton {
    width: max-content;
    justify-self: end;
  }
}

.connectedOrg {
  float: right;
  font-size: 14px;
}

.buyerDeletedAlert {
  grid-column: 1 / -1;
}
