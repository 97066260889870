/* Primitives */

/* bluesNEW(14.06.2024) */
$blue25: #f7faff;
$blue50: #edf3ff;
$blue100: #cadbfe;
$blue200: #9dbcfb;
$blue300: #84aafb;
$blue400: #4f86fa;
$blue500: #2569f9;
$blue600: #074fe9;
$blue700: #0644c6;
$blue800: #04369f;
$blue900: #032772;

/* greysNEW(14.06.2024) */
$grey0: #ffffff;
$grey10: #f7f7f8;
$grey25: #eeeff1;
$grey50: #e6e7ea;
$grey100: #d5d8dd;
$grey200: #b3b8c1;
$grey300: #9299a6;
$grey400: #707a8b;
$grey500: #4f5a6f;
$grey600: #2d3b54;
$grey700: #263247;
$grey800: #1f2839;
$grey900: #171f2c;
$grey1000: #10151e;

/* redsNEW(14.06.2024) */
$red50: #ffeef0;
$red100: #fecdd1;
$red200: #fdabb3;
$red300: #fb8994;
$red400: #fa5766;
$red500: #e9071c;
$red600: #d10519;
$red700: #bf1642;
$red800: #a80514;
$red900: #81040f;

/* greensNEW(14.06.2024) */
$green50: #e5f5eb;
$green100: #d5eedd;
$green200: #b3e1c2;
$green300: #91d3a7;
$green400: #6fc68b;
$green500: #4db870;
$green600: #2bab55;
$green700: #249047;
$green800: #1d743a;
$green900: #0f3e1f;

/* basicsNEW(14.06.2024) */
$white: #ffffff;
$black: #000000;

/* yellowNEW(14.06.2024) */
$yellow50: #fffbf0;
$yellow100: #fff3d3;
$yellow200: #ffecb6;
$yellow300: #ffe498;
$yellow400: #ffd86c;
$yellow500: #ffc932;
$yellow600: #fab800;
$yellow700: #d69e00;
$yellow800: #9e7400;
$yellow900: #423100;
$yellow: #ffc932; /* Direct mapping */

/* purpleNEW(14.06.2024) */
$purple50: #f2eff9;
$purple100: #d7cfed;
$purple200: #c9bee6;
$purple300: #a18ed4;
$purple400: #785dc1;
$purple500: #6345b5;
$purple600: #4732a8;
$purple700: #473181;
$purple800: #392767;
$purple900: #717abd;
$purple: #785dc1; /* Direct mapping */

/* SemanticsNEW(14.06.2024) */

/* semantics // primary */
$sysPrimaryDefault: $blue500;
$sysPrimaryHover: $blue600;
$sysPrimaryPressed: $blue700;
$sysPrimarySubtle: $blue50;
$sysPrimarySubtleAlt: $blue100;

/* semantics // text */
$sysTextDefault: $grey700;
$sysTextSecondary: $grey500;
$sysTextInactive: $grey300;
$sysTextPlaceholder: $grey400;
$sysTextWhite: $grey0;
$sysTextBlack: $black;

/* semantics // background */
$sysBackgroundDefault: $grey0;
$sysBackgroundAlternative: $grey10;
$sysBackgroundInactive: $grey25;
$sysBackgroundInverse: $grey600;
$sysBackgroundOverlay: $grey900;

/* semantics // border */
$sysBorderPrimary: $grey50;
$sysBorderSecondary: $grey10;
$sysBorderInverse: $grey0;

/* semantics // focus */
$sysFocusBr: $blue900;

/* semantics // signal */
$sysSignalDefault: $grey50;
$sysSignalKeyword: $blue100;
$sysSignalCompetitor: $red100;
$sysSignalPartner: $yellow200;
$sysSignalBuyer: $purple100;

/* semantics // success */
$sysSuccessDefault: $green600;
$sysSuccessHover: $green700;
$sysSuccessPressed: $green800;
$sysSuccessSubtle: $green50;
$sysSuccessSubtleAlt: $green100;

/* semantics // destructive */
$sysDestructiveDefault: $red500;
$sysDestructiveHover: $red600;
$sysDestructivePressed: $red800;
$sysDestructiveSubtle: $red50;
$sysDestructiveSubtleAlt: $red100;

/* semantics // warning */
$sysWarningDefault: $yellow600;
$sysWarningHover: $yellow700;
$sysWarningPressed: $yellow800;
$sysWarningSubtle: $yellow50;
$sysWarningSubtleAlt: $yellow100;

/* semantics // info */
$sysInfoDefault: $blue500;
$sysInfoHover: $blue600;
$sysInfoPressed: $blue700;
$sysInfoSubtle: $blue50;
$sysInfoSubtleAlt: $blue100;

/* semantics // status */
$sysStatusPositive: $green500;
$sysStatusNegative: $red400;
$sysStatusInProgress: $yellow500;

// EVERYTHING BELOW THIS LINE IS OLD AND SHOULD NO LONGER BE USED (18.06.2024)
// Neutrals
$black: #000000;
$white: #ffffff;
$dark-grey: #252525; // figma: text colour dark
$grey: #565656; // figma: text colour light
$placeholder-grey: #bfbfbf; // figma: text colour placeholder
$transparent-dark-blue: #2d3b54cc;
$light-grey2: #ebebeb;

// Text
$disabled-grey: #9d9d9d;
$secondary-text-grey: #6c6d70;
// Primary
$dark-blue: #13233f; // rgb(19,35,63)
$mid-blue: #4f86fa; // rgb(79,134,250)
$light-blue: #a3bffc; // rgb(163,191,252)
$link-blue-default: #1890ff;
$link-blue-hover: #40a9ff;

// Secondary
$green: #29d4b1; // rgb(41,212,177)
$yellow: #ffd86c; // rgb(255,216,108)

// Tertiary
$pale-blue: #f1f5ff; // rgb(241,245,255)
$legacy-border-grey: #f0f2f5; // previously used for both backgorund and borders in-app
$app-bg-grey: #f8f7fa;

$light-cyan: #e6f7ff;
$border-grey: #f0f0f0;

// Tertiary - tables
$t-header-bg: #fafafa;
$t-header-selected-bg: #f5f5f5;
$t-row-read-bg: #f6f6f6;
$t-row-selected-hover-bg: $light-cyan;
$t-row-border: #e8e8e8;
$pagination-border: #d9d9d9;
$card-hovered-bg: #fafafa;

// Tags
$light-grey: #eeeeee;
$light-yellow: #fbeecc;
$light-red: #ffbbc2;
$dust-red-1: #fff1f0;
$dust-red-2: #ffccc7;
$dust-red-4: #ff7875;
$light-green: #e0fcc3;
$blue: #cce4f9;
$pink: #ffe7f5;
$orange: #fddfcc;
$mid-grey: #cdcdcb;
$light-brown: #dbc8bf;
$light-purple: #e1d3f8;

// Validation
$v-green: #52c41a; // figma: validation green
$red: #ff4d4f; // figma: validation red
$light-orange: #ffc069; // figma: caution light
$dark-orange: #fa8c16; // figma: caution dark
$warning-button: #ffcb83; // lighter colour for buttons
$light-orange-v2: #fff7e0;

// Other
$polar-green: #237804;
$volcano: #ad2102;
$daybreak-blue: #0050b3;
$neutral-grey: #595959;
$calendula-gold: #d48806;
$sunrise-yellow: #d4b106;
$purple: #785dc1;
$dark-purple: #6d39db;
$mustard-yellow: #eeba2a;
$sea-blue: #29b5d4;
$grass-green: #32be59;
$nugget-gold: #cd911f;
$christmas-orange: #d46729;
$shamrock: #29d48c;
$picton-blue: #298cd4;
$hot-pink: #d42967;
$dark-red: #d42929;
$active-box-shadow: #40a9ff;
$success-green: #389e0d;

// New branding/identity
$v2-pale-blue: #f6f9fe;
$v2-dark-blue: #2d3b54;

// text
$text-primary: #303030;
$text-secondary: #7d7d7d;
$v2-text-secondary: #666666;
$text-dark-blue: #096dd9;
$text-primary-v2: #2b2e33;

// SEO
$seo-dark-grey: #666f80;
$seo-dark-green: #16947a;

// Third-party colours
$hubspotOrange: #f77521;
$salesforceBlue: #20a0df;

$antWarningColour: #faad14;

// Buttons
$disabled-button-background: #f5f5f5;
$disabled-button-border: #d9d9d9;

// New colours
$colorPrimaryTextHover: #3160c4;
$colorPrimary: $blue500;
$colorPrimaryHover: #3160c4;
$colorPrimaryActive: #2951a6;
$colorPrimaryText: #3970e4;
$colorPrimaryBg: #ebf1fc;
$colorPrimaryBgHover: #d7e2fa;
$colorPrimaryBorder: #3970e4;
$colorPrimaryBorderHover: #3160c4;
$colorWarning: #fa822d;
$colorSuccess: #47ab17;
$colorInfo: #3970e4;
$colorInfoHover: #3160c4;
$colorInfoTextHover: #3160c4;
$colorInfoTextActive: #2951a6;
$colorInfoActive: #2951a6;
$colorInfoBg: #ebf1fc;
$colorInfoBgHover: #d7e2fa;
$colorInfoBorder: #3970e4;
$colorInfoBorderHover: #3160c4;
$colorTextBase: #2d3b54;
$colorText: rgba(45, 59, 84, 1);
$colorTextSecondary: rgba(45, 59, 84, 1);
$colorTextTertiary: rgba(45, 59, 84, 0.8);
$colorTextQuaternary: rgba(45, 59, 84, 0.4);
$colorBorder: #ebecf0;
$colorBorderSecondary: #ebecf0;
$colorBgMask: rgba(45, 59, 84, 0.5);
$colorBgSpotlight: rgba(45, 59, 84, 1);
$colorError: #ff3335;

$brand-yellow: #ffc932;
$brand-yellow-hover: #ffc93299;

// new color scheme for tags this is temporary and will be moved over to new library
$gold200: #ffe7a3;
