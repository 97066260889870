@import "styles/colours.scss";

.container {
  background-color: $white;
  padding: 80px 24px 48px 32px;
}

.heading {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 32px;
    font-weight: 700;
    color: $colorTextBase;
    margin-bottom: 8px;
  }

  p {
    color: $sysTextSecondary;
    margin-bottom: 25px;
  }

  .dot {
    color: $blue400;
  }
}
