@import "../../styles/colours";

.badge {
  font-weight: 700;
  background-color: $mid-blue;
  border-radius: 9px;
  color: $white;
  height: 14px;
  width: 14px;
  font-size: 8px;
  line-height: 10px;
  display: inline-flex !important; // ant override
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  &.hidden {
    display: none !important; //override the style above
  }
}
