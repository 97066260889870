@import "styles/colours.scss";
@import "styles/constants.scss";

.planTableContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 36.7% 19% 19% 19%;
  gap: 8px 32px;

  .header {
    grid-column: 1 / -1;
    padding: 8px 0px;
    font-weight: 700;
    border-bottom: none;
    font-size: 20px;
  }

  .row {
    display: contents;
    font-size: 16px;
  }

  .featureColumn,
  .freeColumn,
  .growthColumn,
  .expertColumn {
    padding: 16px 0px;
    border-bottom: 1px solid $colorBorderSecondary;
    box-sizing: border-box;
  }

  .featureColumn {
    grid-column: 1;
    color: $seo-dark-grey;
  }

  .freeColumn,
  .growthColumn,
  .expertColumn {
    grid-column: span 1;
    text-align: center;
  }
}

// Old above, new below

.tableRow {
  border-bottom: 1px solid $colorBorderSecondary;
}

.headerRow {
  background-color: $white;
  position: sticky;
  top: 120px;
  font-size: 20px;
  font-weight: 700;

  // Keep in sync with SubscriptionPlansDetails's .billingInfo
  @media screen and (max-width: calc($sidebarWidthExpanded + 920px)) {
    top: 144px;
  }
  // Keep in sync with SubscriptionButtons's two media queries
  @media screen and (max-width: calc($sidebarWidthExpanded + 680px)) {
    top: 170px;
  }
  // Keep in sync with SubscriptionPlansDetails's .billingInfo
  @media screen and (max-width: calc($sidebarWidthExpanded + 585px)) {
    top: 194px;
  }

  .headerText {
    text-wrap: nowrap;
    padding-top: 16px;
    padding-bottom: 8px;
    padding-left: 16px;

    // Need to wrap "Customer success plans" due to using headerColumns, meaning it needs to wrap to expand
    &.lastHeaderText {
      text-wrap: wrap;
    }
  }

  .headerTextPerFeature {
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    padding-top: 16px;
    padding-bottom: 8px;
    color: $sysTextSecondary;
  }
}

// Need to use ::after to add border to headerRow
// as borders don't work with sticky elements
.headerRow::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid $colorBorderSecondary;
}

.subHeaderCell {
  font-size: 16px;
  font-weight: 500;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;

  .subHeaderText {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.infoIcon {
  height: 16px;
  width: 16px;
  min-width: 16px;
}

.featureTextCell {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;

  .featureText {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.featureStatusCell {
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  vertical-align: middle;
}
