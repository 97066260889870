.relativeDatePicker {
  display: grid;
  gap: 16px;

  margin: 12px 8px;
}

.now {
  display: grid;
  gap: 8px;
}

.pastAndFuture {
  display: flex;
  gap: 8px;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
}

.datePickerHeader {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);

  padding: 12px 8px;
}

.trigger {
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  padding: 0 5px;

  i {
    color: rgba(0, 0, 0, 0.25);
  }
}

.placeholder {
  color: rgba(0, 0, 0, 0.25);
  text-align: left;
  padding-left: 10px;
}

.popover {
  width: 285px;
}
