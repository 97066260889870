@import "styles/colours.scss";

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.container {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  min-width: 0;
  height: 24px;
  padding: 8px;
  background-color: $sysBackgroundAlternative;
  border: 1px solid $sysBorderPrimary;
  border-radius: 4px;
  position: relative;
  z-index: 0;

  &.active {
    background-color: $blue100;
    border: none;
  }

  &.success {
    background-color: $green100;
    border: none;
  }

  &.warning {
    background-color: $yellow100;
    border: none;
  }

  &.error {
    background-color: $red100;
    border: none;
  }
}

.clickableContainer {
  cursor: pointer;
}

.clickableLabel {
  cursor: pointer;
}

.clickableIcon {
  cursor: pointer;
}

.loadingContainer {
  width: 96px;
  height: 24px;

  background-color: $sysBackgroundAlternative;
  border: 1px solid $sysBorderPrimary;
  border-radius: 4px;

  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
  }
}

.label {
  flex: 1;
  min-width: 0;
  margin: 0;
  color: $sysTextDefault;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  position: relative;
  z-index: 1;
}
