@import "styles/colours.scss";

.reviewPage {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(0, 1fr);
  height: 100%;
  padding: 8px;
  grid-gap: 8px;
  background-color: $white;

  .anchorCard {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    padding: 16px;
    border-radius: 4px;
  }

  .scrollablePanel {
    height: 100%;
    overflow: scroll;
  }

  .reviewList {
    height: min-content;
  }

  .sectionContainer {
    background-color: $app-bg-grey;
    padding: 16px;

    .sectionList {
      padding: 0;
      margin: 0;

      display: grid;
      grid-gap: 8px;
    }

    .paddedWrapper {
      padding: 8px;
      background-color: $white;
    }

    .splitSummary {
      list-style: none;
      background-color: $white;
      padding: 8px;

      h3 {
        font-size: 12px;
        color: $grey;
      }
    }

    .splitBuyersWrapper {
      display: grid;
      grid-gap: 8px;
    }

    .buyerItem {
      border: 1px solid $light-grey;
      border-radius: 2px;
    }

    .newBuyer {
      padding: 16px;

      p {
        margin: 0;
        font-size: 16px;
        color: $dark-grey;
      }
    }

    .reviewNoteTrigger {
      color: $red;
      font-weight: 700;
      margin-right: 8px;
      cursor: pointer;
    }
  }
}

.validations {
  margin: 16px 0;
}
