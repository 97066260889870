@import "styles/colours.scss";

.columnListWrapper {
  padding: 8px;
  list-style: none;
  margin: 0;
  background-color: $white;
  border-radius: 2px;
  box-shadow:
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  .columnListItem {
    background-color: $white;
    font-size: 14px;
    line-height: 22px;
    color: $dark-grey;

    .checkBox {
      margin: 0 8px;
    }
  }
}

.dragIcon {
  &:hover {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  &:active {
    cursor: -moz-grabbing;
    cursor: grabbing;
  }
}

.gearIcon {
  color: $colorPrimary;
}
