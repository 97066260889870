@import "styles/colours.scss";

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.icon {
  margin-right: 4px;
  color: $colorPrimary;
  font-size: 24px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: $grey700;
  margin: 0;
}

.content {
  padding: 8px 16px;
  color: $grey500;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  gap: 8px;
}
