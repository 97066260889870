@import "styles/colours.scss";

.dropdownMenu {
  width: max-content;
}

.icon {
  margin-left: 4px;
}

.userIcon {
  margin-right: 4px;
}

.option {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
}

.addAMateBtn {
  text-align: left;
  width: 100%;
  font-weight: normal;
  text-decoration: none;
  padding: 8px;
}

.divider {
  margin: 0;
}
