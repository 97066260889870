@import "styles/colours.scss";
@import "styles/media_queries.scss";

.recordListSection {
  .recordList {
    padding: 16px;
    margin: 0;
    border: 1px solid $legacy-border-grey;
    border-radius: 8px;

    @media print {
      border: none;
      border-radius: none;
      padding: 0;
    }

    hr {
      color: $legacy-border-grey;
      margin: 16px 0;
      border-style: solid;
    }

    // This style affects both recordListItems and the tags which are in <li>
    li {
      list-style-type: none;
    }

    .recordListItem {
      page-break-inside: avoid;
      page-break-after: auto;
      page-break-before: auto;
      break-inside: avoid;
      @media print {
        padding: 0 16px;
      }
    }

    .itemHeader {
      justify-content: space-between;
      align-items: center;
      display: flex;
      margin-bottom: 8px;
    }

    .itemTitle {
      font-size: 18px;
      line-height: 125%;
      margin-bottom: 8px;
      @media print {
        font-size: 14px;
      }

      a {
        color: $link-blue-default;
        font-weight: 400;

        &:hover,
        :active {
          text-decoration: underline;
        }
      }
    }

    .detailsWrapper {
      display: flex;
      flex-direction: column;

      @media (min-width: map-get($breakpoints, lg)), print {
        flex-direction: row;
      }

      img {
        color: $mid-blue;
        margin-right: 8px;
        vertical-align: -0.1em;
      }
    }

    .recordDetail {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 125%;
      color: $dark-grey;
      font-weight: 400;

      @media (min-width: map-get($breakpoints, lg)), print {
        margin-right: 30px;
      }
      @media print {
        font-size: 14px;
      }
    }

    .publishDate {
      font-size: 12px;
      line-height: 18px;
      color: $grey;
      white-space: nowrap;
      min-width: min-content;
      margin: 0;
    }

    .stage {
      font-size: 14px;
      margin-right: 16px;
      @media print {
        font-size: 12px;
      }
    }

    .recordDescription {
      color: $grey;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media print {
        font-size: 12px;
      }
    }
  }
}
