@import "styles/colours.scss";

.menuContent {
  min-width: 200px;
  border: none !important;
  overflow-x: hidden;
  border-radius: 4px;
}

.popoverContainer {
  display: flex;
  flex-direction: column;
  max-height: 350px;
}

.listHeader {
  font-size: 12px;
  color: $text-secondary;
  margin: 0;
  // Could not achieve this with configProvider, no way no how
  margin-bottom: -8px;
}

.supplierCategory {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .showOnHover {
    display: none;
    fill: none;
  }

  &:hover {
    .showOnHover {
      display: block;
    }
  }
}

.signalIcon {
  margin-right: 4px;
  color: $colorPrimary;
}

.external {
  height: 13px;
  width: 13px;
}

.viewSigSettings {
  margin-left: 8px;
  padding: 4px 8px;
}
