.nameColumn {
  display: grid;
  grid-template-columns: 1fr max-content;
  line-height: 32px;
}

.titleLink {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 700;

  &:hover {
    text-decoration: none;
  }
}
