@import "styles/colours.scss";

.buyerCategoriesCell {
  line-height: 125%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 100%;

  &.labelSizeSmall {
    font-size: 12px;
  }
}

// this is the item within the popover, it has a larger font size
// than the categories shown under the buyer
.buyerCategoryItem {
  font-size: 14px;
}

.popoverTrigger {
  color: $sysPrimaryDefault;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
}
