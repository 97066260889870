@import "styles/colours.scss";

.label {
  display: inline-block;
  margin-bottom: 4px;

  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0px;
  }
}

.errorMessage {
  color: $red;
}

.subLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: $secondary-text-grey;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.space {
  row-gap: 8px;
}

.addAllSelectOption {
  color: $mid-blue;
}

.loadingSkeleton {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
