@import "styles/colours.scss";

.commentInput {
  display: grid;
  grid-gap: 8px;

  .inputContainer {
    display: grid;
    grid-template-columns: 32px auto;
    grid-gap: 8px;
  }

  $rowHeight: 22px;

  .textInput {
    resize: none;
    font-size: 14px;
    scroll-behavior: auto;
    line-height: $rowHeight;
    color: $dark-grey;
    max-height: ($rowHeight * 5) + 10; // max 5 rows high
    overflow-y: auto;
  }

  .saveContainer {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr max-content max-content;
    gap: 16px;
  }

  .mentionHelp {
    margin-left: 42px;
    font-weight: 700;
    font-size: 12px;
  }

  .cancelContainer {
    display: grid;
    grid-gap: 16px;
    align-items: center;
    justify-content: end;
    grid-template-columns: repeat(3, max-content);

    p {
      margin: 0;
    }
  }
}
