@import "styles/colours.scss";
@import "../../pages/app/MyFeedPage.module.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
}

.actions {
  display: flex;
  gap: 8px;
}

.searchInput {
  width: 320px;
}

.filterIcon {
  color: $colorPrimary;
}

.clearSelected {
  padding-right: 8px;
  margin-right: 8px;
}

.saveSuppliers {
  padding-right: 8px;
}
