@import "styles/colours.scss";

.blurred,
.blurred:hover {
  color: transparent !important;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  letter-spacing: -3px;
  user-select: none;
}

.list {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  grid-row-gap: 24px;

  @media print {
    grid-row-gap: 8px;
  }

  li {
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-before: auto;
    break-inside: avoid;

    > div {
      height: 100%;
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
  break-inside: avoid;

  h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.container {
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}

.clickable {
  cursor: pointer;
}

.contactCard {
  background: $white;
  border: 1px solid $legacy-border-grey;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px;
  width: 332px;
  color: $dark-blue;
  margin-right: 16px;
  margin-bottom: 16px;

  @media print {
    width: 220px;
    margin: 0 8px 8px 0;
  }
}

.title {
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  display: block;
  padding-bottom: 2px;

  @media print {
    font-size: 14px;
  }
}

.name,
.categories {
  font-size: 14px;
  line-height: 125%;
  color: $dark-grey;
  display: block;
  padding-top: 2px;
  padding-bottom: 2px;
  // in unusual cases where the email is very long we don't want it to leave the box, just wrap
  word-break: break-word;

  @media print {
    font-size: 12px;
  }
}
