.select {
  > div > div {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    padding: 8px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    :focus-within {
      margin: 0;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 12px;
  height: 12px;
}
