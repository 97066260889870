@import "styles/colours";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 8px;
  border-radius: 4px;
  outline: none;
}

.container:focus {
  border-color: $colorPrimaryBorder;
}

.inputField {
  flex-grow: 1;
  padding: 0px 8px;
  min-height: 32px;
}

.disabledInputField {
  @extend .inputField;
  background-color: $disabled-button-background;

  &:hover {
    background-color: $disabled-button-background;
  }
}

.signalIcon {
  color: $colorPrimary;
}

.saveSignalBtn {
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
  width: 100%;
}
