@import "styles/colours.scss";
@import "styles/tag_helpers.module.scss";

.recordList {
  list-style: none;
  padding: 0;
  margin: 0 -16px -16px;

  .recordCard {
    margin: 16px;
    &:first-child {
      margin-top: 0px;
    }
    border: 1px solid $legacy-border-grey;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
  }

  .matchReasonContainer {
    width: 100%;
    display: flex;

    .publishDate {
      font-size: 12px;
      line-height: 18px;
      color: $grey;
      white-space: nowrap;
      min-width: min-content;
      margin: 0;
    }

    .matchList {
      @extend .nonTagCellTagsContainer;

      .matchItem {
        @extend .nonTagCellTag;
      }

      .keywordMatch,
      .cpvCodeMatch {
        @extend .keywordMatchTag;
      }

      .partnerMatch {
        @extend .partnerMatchTag;
      }

      .competitorMatch {
        @extend .competitorMatchTag;
      }
    }
  }
  .infoContainer {
    width: 75%;

    .title {
      font-size: 16px;
      line-height: 24px;
      color: $dark-blue;
      font-weight: 400;
      margin: 0;
      display: inline;
    }

    .stageLabel {
      margin-right: 8px;
    }

    .buyerName {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
      color: $dark-grey;
    }
  }

  .actionContainer {
    display: flex;
    position: relative;
    align-items: center;
  }
}
