@import "styles/colours.scss";

.linkListContainer {
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      display: flex;
      page-break-inside: avoid;
      page-break-after: auto;
      page-break-before: auto;
      break-inside: avoid;
      margin-bottom: 16px;
    }
  }
  .imageContainer {
    height: 128px;
    width: 190px;
    min-width: 190px;
    margin-right: 24px;

    &.placeholderContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid $legacy-border-grey;
    }

    .articleImg {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 4px;
      border: 1px solid $legacy-border-grey;
    }

    .placeholderImg {
      height: 30px;
      width: 40px;
    }
  }

  .textContainer {
    .title {
      font-size: 18px;
      line-height: 125%;
      margin-bottom: 8px;
      @media print {
        font-size: 14px;
      }
      a {
        font-weight: 400;
      }
    }
    p {
      color: $dark-grey;
      margin-bottom: 8px;
    }

    .description {
      font-size: 16px;
      line-height: 125%;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media print {
        font-size: 12px;
      }
    }
    .caption {
      font-size: 14px;
      line-height: 125%;

      @media print {
        font-size: 12px;
      }
    }

    small {
      font-size: 12px;
      line-height: 125%;
      color: $grey;
      @media print {
        font-size: 10px;
      }
    }
  }
}
