@import "styles/colours";

.panelHeader {
  display: grid;
  grid-template-columns: 21px 1fr;
  grid-column-gap: 16px;
  grid-template-areas: "sparkles-icon header-inner";
}

.panelHeaderInner {
  display: grid;
  grid-template-columns: 1fr 42px;
  grid-template-rows: 28px 18px;
  grid-template-areas:
    "title collapse-icon"
    "subtitle collapse-icon";

  > h6 {
    grid-area: title;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    align-self: center;
    margin-bottom: 0;
  }

  > p {
    grid-area: subtitle;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 0;
  }
}

.collapseIcon {
  grid-area: collapse-icon;
  margin: auto 0;
}

.items {
  padding-left: 0;
  margin-bottom: 0;

  li {
    padding: 8px 0px;
    list-style: none;
    display: grid;
    grid-template-columns: 26px 1fr;
    grid-template-rows: 26px 1fr;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 12px;
    }
  }
}

.sparkles {
  grid-area: sparkles-icon;
  align-self: center;
  justify-self: center;
}

.collapse {
  background-color: rgba(255, 233, 173, 0.2);
}
