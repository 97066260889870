@import "styles/colours.scss";

.pairContainer {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.labelSizeSmall {
    font-size: 12px;
  }

  .divider {
    margin: 0 4px;
  }
}
