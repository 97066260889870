@import "styles/colours.scss";
@import "styles/fonts.scss";

.contactBookImage {
  padding-top: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}

.modalHeader {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: $grey900;
}

.formModal {
  display: flex;
  flex-direction: column;

  .formField {
    margin-bottom: 24px;
  }
}

.description {
  font-weight: 400;
  color: rgba($grey900, 0.68);
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 32px;
}
.subDescription {
  font-weight: 400;
  color: rgba($grey900, 0.68);
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 28px;
  margin-top: 16px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: right;

  .okButton {
    margin-left: 8px;
    width: fit-content;
    background-color: $text-dark-blue;
    color: white;
  }
}
