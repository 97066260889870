@import "../../styles/colours.scss";

.steps {
  display: flex;
  justify-content: space-between;
  margin: 0 -4px;
  .step {
    margin: 0 4px;
    flex: 1;
    height: 4px;
    background-color: $pale-blue;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    .bar {
      width: 0%;
      will-change: transform;
      background-color: $mid-blue;
      height: 100%;
      border-radius: 2px;
      display: inline-block;
      position: absolute;

      &__active {
        width: 100%;
      }
    }
  }
}
