@import "styles/colours.scss";

.saveSupplierButton {
  i {
    // To match the ant transition on the tooltip and border
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.saveSupplierButton:hover,
.saveSupplierButton:active,
.saveSupplierButton:focus {
  i {
    stroke: $colorPrimary;
  }
}

.bookmarkButton {
  @extend .saveSupplierButton;
  padding: 4px 8px;
}

.caretDown {
  size: 12px;

  svg {
    height: 12px;
    width: 12px;
  }
}
