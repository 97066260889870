@import "styles/colours.scss";
@import "styles/media_queries.scss";

.modalContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-height: 100%;
  overflow: hidden;
  height: 100%;

  .image {
    border-radius: 8px 0px;

    img {
      border-radius: 8px 0px;
      width: 100%;
      height: 100%;
    }

    @media (max-width: map-get($breakpoints, xl)) {
      img {
        border-radius: 8px 0px 0px 8px;
      }
    }

    flex: 1;
    background: linear-gradient(270deg, $mid-blue 0%, $sysPrimaryDefault 100%);
  }

  .formContainer {
    flex: 3;
    display: flex;
    padding: 16px 136px 96px 136px;
    flex-direction: column;
    justify-content: center;

    .submitButton {
      height: 47px;
      font-size: 16px;
      font-weight: 600;
    }

    h1 {
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      color: $sysTextSecondary;
    }

    .formFields {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .footer {
      margin-top: 8px;

      p {
        color: $sysTextSecondary;
        font-size: 12px;
        margin-bottom: 0px;
      }

      .link {
        color: $sysPrimaryDefault;
        font-size: 12px;
      }
    }

    .submittedFormContent {
      display: flex;
      flex-direction: column;

      p {
        font-size: 24px;
        font-weight: 500;
      }

      .noticeFeedButton {
        height: 47px;
        background-color: $sysPrimaryDefault;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
