@import "./colours.scss";
@import "./ant_utils.scss";
@import "styles/fonts";

.addButton,
.editButton {
  padding: 4px 8px;
  cursor: pointer;
}

.editButton {
  color: $mid-blue;
}

// dropdown options (dropdown is appended to end of body)
.dropdown {
  // don't show "no results" in dropdown, just "add item" option
  &:not(.dropdownReadonly)
    :global(.ant-select-dropdown-menu-item):global(
      .ant-select-dropdown-menu-item-disabled
    )[unselectable="on"] {
    display: none;
  }

  & .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  & :global(.ant-select-selection--multiple) :global(.ant-select-selection__rendered) > ul > li {
    background-color: transparent;
    border: none;
    height: 24px;
    line-height: 24px;
  }
}

@mixin warning-tag {
  i:global(.anticon-exclamation-circle) {
    color: $dark-grey;
    margin-right: 5px;
  }

  background-color: $warning-button;
}

@mixin loading-tag {
  background-color: $sysInfoSubtle;
}

.nonTagCellTagsContainer {
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0 0 -8px; // offset for item mb
}

.nonTagCellTag {
  font-family: $font-default;
  padding: 1px 8px;
  font-size: 14px;
  line-height: 20px;
  color: $dark-grey;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 2px;
  display: inline-block;

  span {
    white-space: nowrap;
  }
}

.vertical {
  display: flex;
  flex-direction: column;
}

.moreSignals {
  display: inline-block;
}

.keywordMatchTag {
  background-color: $blue;
}

.partnerMatchTag {
  background-color: $light-yellow;
}

.competitorMatchTag {
  background-color: $light-red;
}

.buyerMatchTag {
  background-color: $light-purple;
}
