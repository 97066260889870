@import "styles/colours.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 22px;
  border-bottom: 1px solid $grey50;
}

.titleContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.title {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
}

// compensate for padding
.buttonWrapper {
  margin-right: -16px;
}

.icon {
  color: $colorPrimary;
}
