.hoverContainer {
  .showOnHover {
    display: none;
  }

  .hideOnHover {
    display: block;
  }

  &:hover {
    .showOnHover {
      display: block;
    }

    .hideOnHover {
      display: none;
    }
  }

  .alwaysShow {
    display: block;
  }
}
