@import "styles/colours.scss";

.elipsisText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

// When in buyer profile, we do not need the padding or margin
.removeSpacing {
  margin: 0px;
  padding: 0px;
}

.contactCellContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contactDetail {
  color: $link-blue-default;
  cursor: pointer;
}

.contactIcon {
  margin-right: 4px;
}

.hiddenOverflow {
  overflow-x: hidden;
}

.pagination {
  display: flex;
  margin: 16px 0px;
  justify-content: center;
}

.nextButton {
  color: $white;
  border-color: $white;
  background-color: #1a3369;
}

.backButton {
  background-color: transparent;
  border: none;
  color: $white;

  &:hover {
    color: $white !important;
  }
}

.outreachDemoImage {
  width: 100%;
  border-radius: 8px;
}

.bannerImg {
  width: 64px;
  height: 64px;
  padding: 5px 11px 6px 11px;
  flex-shrink: 0;
}
