@import "../../styles/colours";

.property {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  > small {
    font-size: 12px;
  }

  > div {
    font-size: 14px;
    color: $text-primary;
  }
}

.propertyInline {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.title {
  color: $text-secondary;
  min-width: 30%;
}

.divider {
  margin: 0;
}
