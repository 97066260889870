@import "styles/colours.scss";
@import "styles/ant_component_overrides/ant_tag_multi_select.scss";

.content {
  display: grid;
  gap: 8px;
  line-height: 22px;
  color: $dark-grey;
}

.emailInput {
  display: grid;
  gap: 1px;
}

.label {
  font-size: 12px;
  line-height: 18px;
  color: $grey;
}

.hide {
  display: none;
}

.actions {
  button {
    display: inline-flex;
    align-items: center;
    padding: 7px 16px;
    vertical-align: middle;
  }
}

.teamPageLink {
  margin-top: 16px;
}
