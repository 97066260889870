@import "styles/colours.scss";

.container {
  margin-bottom: 16px;
}

.header {
  display: flex;
}

.userName,
.displayTime,
.displayEditedTime {
  margin-left: 8px;
}

.userName {
  font-weight: 700;
}

.editButtons {
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-items: center;
}

.contentsContainer {
  margin-left: 40px;
  word-wrap: break-word;
}

.content {
  background-color: $t-header-selected-bg;
  border-radius: 8px;
  padding: 8px;
  white-space: pre-wrap;
}

.showMoreButton {
  padding-left: 4px;
}
