@import "styles/colours.scss";
@import "styles/fonts";

.disabledNoteTooltip {
  font-size: 12px;
}

.paywallIcon {
  margin: 0px 8px;
}
