@import "../styles/colours.scss";

@mixin green-button {
  &:global(.ant-btn) {
    color: $white;
    background-color: $green;
    border-color: $green;

    &:hover {
      background-color: lighten($color: $green, $amount: 10%);
      border-color: $green;
    }
  }
}

@mixin buttonise-link-on-hover {
  background-color: transparent;
  border-style: solid;
  border-color: transparent;
  border-width: 0 1px 1px 0;
  padding: 8px 12px;
  margin: 4px;
  border-radius: 4px;

  &:hover,
  &:active {
    border-color: rgba(0, 0, 0, 0.1);
  }

  &:hover {
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.15);
  }

  &:active {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1) inset;
  }
}

@mixin no-scrollbar {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
