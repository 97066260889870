@import "styles/colours.scss";
@import "styles/fonts.scss";

.markdown {
  font-family: $font-default;
  color: inherit;

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 125%;
  }
  h2 {
    font-weight: 500;
    font-size: 28px;
    line-height: 125%;
  }
  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
  }

  table {
    // Somewhat arbitrary but when you only have two columns for example you don't want the table to be too small
    // But 100% makes it look too stretched out
    // We can revisit this later
    min-width: 50%;
    font-size: 16px;
    line-height: 125%;

    th {
      color: $dark-grey;
      white-space: nowrap;
    }

    a,
    th {
      font-weight: 400;
      font-size: inherit;
    }

    td,
    th {
      padding: 16px 8px;
    }

    tbody > tr {
      border-bottom: 1px solid $legacy-border-grey;
    }
  }
}
