@import "styles/colours.scss";

.textLink {
  color: $colorPrimaryText;
  &:hover {
    text-decoration: underline;
    color: $colorPrimaryTextHover;
  }
  padding: 6px 11px;
  text-align: center;
  display: inline-block;
}
