@import "../../styles/ant_utils.scss"; // additional styles
@import "../../styles/colours";
@import "../../styles/media_queries.scss";
@import "../../styles/constants.scss";

.pageTopnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter", "Lato", sans-serif;
  font-size: 16px;
  max-width: 100vw !important;
  box-shadow: 0px -1px 0px 0px rgba(37, 37, 37, 0.1) inset;
  z-index: $topBarZIndex;
  padding: 0 32px;

  @include media-mobile() {
    padding: 0 24px;
  }

  .topnavLogo {
    height: 32px;
    margin-right: 32px;
  }

  .topnavPartnerLogo {
    margin-right: 32px;
  }

  @media (max-width: map-get($breakpoints, xl)) {
    .topnavLogo,
    .topnavPartnerLogo {
      margin-right: 16px;
    }
  }

  .flexAlignCenter {
    display: flex;
    align-items: center;
  }

  .logoAndMenu {
    flex: 6 1;
    display: flex;
    justify-content: flex-start;
  }

  .userItemsContainer {
    flex: 3 0 50px;
    gap: 24px;

    &.onTrial {
      @include media-mobile() {
        flex-grow: 6;

        > a > span {
          white-space: unset;
          line-height: 22px;
          max-height: 64px;
        }
      }
    }

    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  .logoutButton {
    font-weight: bold;
    padding: 0;
  }
}

.trialCounterContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: initial;
  flex: 1 1 auto;
  font-size: 11px;
  margin-right: 32px;
  min-width: 210px;

  @media (max-width: map-get($breakpoints, lg)) {
    min-width: 170px;
    margin-right: 16px;
  }
}

.trialCounterHeader {
  font-size: 13px;
}

.trialCounterCountdown {
  flex: 1 0;
  font-size: 13px;
}

.progressBarContainer {
  display: flex;
  width: 100%;
  align-items: center;

  .antProgressWrapper {
    flex: 2 1;
    margin-right: 8px;
    line-height: initial !important; //override ant without mentioning class names
  }

  a {
    font-weight: bold;
  }
}

.upgradeButton {
  display: flex;
  font-weight: 500;
  gap: 8px;
  align-items: center;
  margin-right: 8px;
  background: $brand-yellow;
  color: $text-primary-v2;
  border: none;
  stroke: none;

  &:hover,
  &:focus,
  &:active {
    background: $brand-yellow-hover;
    color: $text-primary-v2 !important;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
}

.loginButton {
  margin-right: 16px;
}

.menuButton {
  color: inherit;
}

.navBarButton {
  font-size: 16px;
  line-height: 24px;
  color: $grey;

  &:focus {
    color: $colorPrimaryTextHover;
  }
}
