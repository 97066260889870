@import "styles/colours.scss";
@import "styles/layouts.scss";
.resizeHandle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.container {
  background-color: $white;
  table {
    border: 1px solid $grey50;
    border-radius: 4px;
    tr {
      background-color: transparent;
    }
  }
}

.clickableRow {
  cursor: pointer;
}

.tableRow:hover {
  .outlineOnTableRowHover {
    border-color: $colorPrimaryTextHover;
    background-color: $white;
    color: $colorPrimaryTextHover;
    padding: 6px 8px;
    border-radius: 4px;
  }
}

.outlineOnTableRowHover {
  border: 1px solid transparent;
  border-radius: $border-radius-sm;
  padding: 6px 8px;
  transition: none; // overrides ant's behaviour of slowly transitioning the color. Looked weird for us here
}

.inRowStatButton {
  font-size: 14px;
  line-height: 20px;
  min-width: 32px;
  height: 32px;
  display: inline-block;
  text-align: center;
}

.inRowStatLink {
  @extend .inRowStatButton;
  @extend .outlineOnTableRowHover;
  font-weight: 700;
  color: $colorPrimaryText;
  &:hover {
    text-decoration: underline;
    color: $colorPrimaryTextHover;
  }
}

.inRowStatText {
  @extend .inRowStatButton;
  padding: 6px 8px;
}

.tableOverflow {
  table {
    min-width: unset !important;
  }
}
