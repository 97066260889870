@import "styles/colours.scss";

.titleBar {
  display: flex;
  align-items: center;
  max-width: 500px;

  .title {
    font-size: 14px;
    font-weight: 700;
    flex-grow: 1;
    flex: 1;
    display: block;
    margin-right: 16px;
  }
  a.title {
    color: $mid-blue;
  }
  h4.title {
    color: $dark-blue;
  }
}

.popoverContent {
  max-width: 500px;
  white-space: pre-line;
  max-height: 500px;
  overflow-y: auto;
  overflow-wrap: break-word;
}

.popover {
  :global(.ant-popover-inner) {
    max-width: 500px;
  }
}
