@import "styles/colours";

.scrollableDiv {
  height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 7px;
    float: right;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $placeholder-grey;
  }
}

.optionsHeader {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: sticky;
  background-color: $white;
  width: 100%;
  z-index: 100;
  top: 0;
}

.labelAndIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  max-height: 32px;
  gap: 8px;
}

.labelAndIcon:hover {
  background-color: $colorPrimaryBg;
  border-radius: 4px;
}

.label {
  margin-left: 4px;
}

.divider {
  margin: 8px 0px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.backBtn {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 4px;
  border: none;
  box-shadow: none;
}
