@import "styles/colours.scss";

.documentForm {
  padding: 20px;
}

.errors {
  color: $red;
  margin-top: 10px;
  margin-bottom: 10px;
}

.error {
  display: block;
}

.formSpacer {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}
