@import "styles/colours.scss";

.subscriptionPlans {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(320px, 100%), 1fr));
  gap: 24px;
  margin-top: 80px;
}

.plan {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 56px 24px 24px 24px;
  border-radius: 4px;
  border: 1px solid $grey50;
  gap: 32px;

  .planHeader {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .billingInfo {
    font-size: 12px;
    color: $sysTextSecondary;
  }

  .description {
    min-height: 88px;
  }

  .scrollToPlansButton {
    display: flex;
    height: 60px;
    width: 100%;
    border: none;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    color: $sysPrimaryDefault;
    box-shadow: none;
    line-height: 1;
  }

  .arrowDownIcon {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 0 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    color: $colorTextBase;
    margin: 0;
  }

  p {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .activePlanHeader {
    background-color: $blue500;
    color: $white;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 0;
    margin-top: -56px;
    margin-left: -24px;
    margin-right: -24px;
  }

  .graphic {
    align-self: flex-start;
  }
}

.activePlan {
  border: 2px solid $blue500;
}

.featuresHeading {
  font-size: 16px;
  font-weight: 500;
}

.listItem {
  color: $sysTextSecondary;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  font-size: 14px;
  gap: 8px;

  .checkIcon {
    svg {
      width: 24px;
      height: 24px;
      color: $sysPrimaryDefault;
    }
  }
}

.planIcon {
  width: 16px;
  height: 16px;
}
