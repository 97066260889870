@import "styles/colours";

.subtitle {
  font-size: 24px;
  font-weight: 700;
  color: $text-primary;
  text-align: left;
  margin-left: 0;
  font-size: 20px;
}

.noticeTimeline {
  min-width: 80%;
  max-width: 100%;
}
