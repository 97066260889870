@import "styles/colours.scss";

.container {
  display: flex;
  max-width: 1280px;
  width: 100%;
  justify-content: space-between;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 440px;
}

.section {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 16px;
  margin: 0;
}

.divider {
  color: $grey50;
  margin: 0;
}

.card {
  border: 1px solid $grey50;
  border-radius: 6px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.smallTitle {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.mediumTitle {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.commentsContainer {
  padding: 0;
}

.contactLabel {
  color: $text-secondary;
  min-width: 112px;
  max-width: 112px;
}

.description {
  white-space: pre-wrap;

  a {
    overflow-wrap: anywhere;
  }
}

@media screen and (max-width: 880px) {
  .rightColumn {
    order: 1;
  }

  .leftColumn {
    order: 2;
  }

  .container {
    flex-direction: column;
  }
}

@media screen and (max-width: 440px) {
  .rightColumn {
    width: 100%;
  }
}
