@import "styles/colours";
@import "styles/layouts";
@import "styles/fonts";

.banner {
  display: flex;
}

.bannerContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: $white;
  border: $border-basic-light;
  border-radius: 4px;
  flex: 1;
  min-height: 40vh;
}
