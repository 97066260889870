@import "../../styles/colours";
@import "../../styles/fonts";

.container {
  display: flex;
  gap: 8px;
  min-width: 345px;
}

.disabledPopupContainer {
  padding-top: 0px;
}

.disabledPopupContent {
  padding: 4px;
  max-width: 400px;
}

.disabledPopupHead {
  font-family: $font-default;
  font-weight: 700;
}

.disabledPopupTxt {
  color: $v2-text-secondary;
}

.primaryButton {
  display: flex;
  align-items: center;
}

.paywallStar {
  margin-left: 8px;
  height: 20px;
  width: 20px;
}

.cancelButton {
  min-width: 32px;
}
