@import "styles/colours.scss";
@import "styles/fonts.scss";

.contactUsWidgetContainer {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 16px;
  z-index: 2000;
}

.contactUsWidget {
  display: grid;
  gap: 8px;
  width: auto;
  height: auto;
  margin: 16px auto;
  padding: 16px;

  background: $white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: fixed;
  bottom: 16px;
  font-family: $font-default;
}

.contactUsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
  }
}

.contactMethods {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  align-items: center;
}

.contactMethod {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 8px;

  align-items: flex-start; // text of the contact method should be aligned to the top

  i {
    color: $white;
    font-size: 24px;
  }

  .iconCircle {
    display: flex;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: $link-blue-default;
    justify-content: center;
    align-items: center;

    .icon {
      color: $white;
      font-size: 20px;
    }
  }

  .iconNoCircle {
    display: flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 20px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    color: $link-blue-default;
    margin-bottom: 2px;
    white-space: nowrap;
  }

  p {
    color: $grey;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }
}
