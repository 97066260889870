@import "styles/colours.scss";

.highlight {
  background-color: #fff2cc;
  font-weight: bold;
}

.mention {
  color: $sysPrimaryDefault;
}
