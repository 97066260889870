@import "styles/colours.scss";
@import "../../components/filter_form/SharedFilterForm.module.scss";

.content {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.filterBar {
  display: flex;
  justify-content: space-between;
}

.filterItems {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.searchInput {
  width: 320px;
}

.filterIcon {
  color: $colorPrimary;
}

.viewFilters {
  display: flex;
  column-gap: 7px;
  align-items: baseline;
  flex-wrap: wrap;
}

.actionContainer {
  display: flex;
  gap: 8px;

  .clearSelected {
    padding-right: 8px;
    margin-right: 8px;
  }
}

.fullHeightBanner {
  display: flex;
  min-height: 85vh;
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $grey50;
  border-radius: 4px;
  min-height: 45vh;
}

.drawerBody {
  @extend .drawerBody;
}
