@import "styles/colours.scss";
@import "styles/fonts.scss";

.header {
  display: grid;
  gap: 20px;
  font-family: $font-default;
  color: $dark-blue;
  background-color: rgba(79, 134, 250, 0.05);
  padding: 40px 64px;
  margin: 0 -64px; // This is to make the header section full width;
  @media print {
    margin: 0;
  }

  .logosContainer {
    display: flex;
    justify-content: space-between;
  }

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 125%;
    padding: 0;
  }

  .markdownText {
    color: $dark-blue;
    // important required to override some global styles 🙄
    ul {
      padding-left: 20px !important;
      list-style: disc !important;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 28px;
  line-height: 125%;
}

.description {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 100px;
}
