.qualificationDropdown {
  width: min-content;
  background-color: transparent;

  padding: 0;

  > div {
    border: none;
    margin: 0;
    height: 24px;

    &:focus,
    &:active,
    &:hover {
      border: none;
      box-shadow: none;
    }
  }
}
