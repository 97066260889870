@import "styles/colours.scss";
@import "styles/constants.scss";

.no-scroll {
  position: fixed;
  overflow: hidden;
}

.smallScreenWarning {
  min-height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: calc(#{$topBarZIndex} - 1);
  background-color: white;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    max-width: 400px;
    text-align: center;
    padding: 16px;

    h1 {
      font-size: 32px;
      line-height: 48px;
      color: $dark-blue;
      margin-bottom: 20px;
      font-weight: 400;
    }

    button {
      font-weight: 700;
    }
  }
}
