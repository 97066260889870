@import "styles/colours.scss";

.modalHeader {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 24px;
  align-items: center;

  h1 {
    font-size: 16px;
    font-weight: 700;
    color: $dark-grey;
    margin-bottom: 0px;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    margin-bottom: 0px;
  }
}
