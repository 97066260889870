@import "styles/colours.scss";

.textLink {
  color: $colorPrimaryText;
  &:hover {
    text-decoration: underline;
    color: $colorPrimaryTextHover;
  }
}

.textLink[disabled] {
  color: $colorTextBase;
}

.backToLink {
  font-weight: 700;
}

.externalLinkIcon:global(.anticon) {
  color: inherit;
  fill: currentColor;
  margin-left: 0.3em;
  vertical-align: middle;
  font-size: 12px;
}
