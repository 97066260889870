$font-default: "Inter", "Lato", sans-serif;

$font-edmondsans: "Edmondsans", sans-serif;
$font-lato: "Lato", sans-serif;
$font-inter: "Inter", sans-serif;
$native-sans-serif:
  -apple-system,
  BlinkMacSystemFont,
  ‘Segoe UI’,
  ‘Roboto’,
  ‘Oxygen’,
  ‘Ubuntu’,
  ‘Fira Sans’,
  ‘Droid Sans’,
  ‘Helvetica Neue’,
  sans-serif;

@mixin edmondsans {
  font-family: $font-edmondsans;
}
