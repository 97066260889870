@import "styles/colours.scss";
@import "styles/layouts";

.modal {
  min-width: 1200px;
  min-height: 750px;
  padding: 0;
}

.content {
  display: flex;
  justify-content: space-between;

  h2 {
    margin: 0;
    font-weight: 700;
  }

  h3 {
    margin: 0;
    font-weight: 500;
  }
}

.subTitle {
  color: $secondary-text-grey;
}

.sidePanel {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 32px;

  .outreachHeader {
    padding-bottom: 8px;
  }
}

.experimentContainer {
  @extend .sidePanel;
  align-items: center;
  justify-content: center;
  background-color: rgba(57, 112, 228, 0.12);
}

.formContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
}

.intentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}

.intentCard {
  height: 110px;
  min-width: 500px;
  cursor: pointer;
  border: $border-basic-mid;
  border-radius: $border-radius-md;

  &:hover {
    background-color: $light-cyan;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
}

.intentCardSelected {
  @extend .intentCard;
  background-color: $light-cyan;
  border-color: $mid-blue;
}

.generateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.generateBtn {
  padding: 5px 12px 5px 8px;
}

.actionItems {
  display: flex;
  gap: 8px;
}

.numberOfResults {
  max-width: 56px;
  border: 1px solid $light-grey2;
}

.results {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  max-height: 900px;
  padding-right: 4px;

  &::-webkit-scrollbar {
    width: 7px;
    float: right;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $placeholder-grey;

    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $placeholder-grey;
  }
}

.dividerVertical {
  width: 1px;
  border: 1px solid $light-grey2;
}

.largeIcon {
  height: 56px;
  width: 56px;
}

.icon {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.divider {
  margin-top: 0px;
  margin-bottom: 8px;
}
