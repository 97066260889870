@import "../../styles/colours.scss";

.supplierTextLink {
  color: $colorPrimary;

  &:hover {
    text-decoration: underline;
    color: $colorPrimaryHover;
  }

  align-items: flex-end;
  display: flex;
  gap: 8px;

  img {
    padding: 4px;
    background-color: $app-bg-grey;
    border-radius: 4px;
  }
}

.lots {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lotName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
