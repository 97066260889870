@import "styles/colours.scss";

.popoverContainer {
  display: grid;
  grid-template-columns: 25% 75%;
  max-height: 300px;
  max-width: 550px;
  margin: 6px 0px;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 32px;
  color: $text-primary-v2;
  gap: 8px;

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }

  p {
    margin: 0;
    color: $v2-text-secondary;
  }
}
