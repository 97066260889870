.title {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cpvCode {
  padding: 5px 12px;
  border: 1px solid #e6e7ea;
  border-radius: 4px;
  background-color: #f7f7f8;
}

.cpvCodesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
