@import "styles/colours.scss";

.page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100%;
}

.pageContent {
  padding: 16px;
  border: 1px solid $border-grey;
  min-height: 100%;

  h2 {
    margin-bottom: 0px;
    color: $colorPrimary;
    font-size: 20px;
    font-weight: 700;
  }

  .listContainer {
    padding: 16px;
    background-color: $app-bg-grey;
    border-radius: 4px;

    ul {
      margin: 0 0 -8px 0;
      padding: 0;
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;
      margin-bottom: 8px;
      border: 2px solid $v-green;
      border-radius: 8px;
      background-color: $white;
      padding: 16px;
    }

    .unverifiedItem {
      @extend .item;
      border: 2px solid $antWarningColour;
    }
  }

  .buttonContainer {
    margin: 16px 0;
    display: flex;
    align-items: center;
    gap: 16px;
    width: max-content;
  }
}

.newBuyerDescription {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bullets {
  @extend .newBuyerDescription;
  padding-inline-start: 20px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  font-size: 16px;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
  -webkit-box-orient: vertical;
  font-weight: 600;
}

.removeButton {
  font-size: 18px;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.oscarIdText {
  font-weight: 600;
  color: $colorPrimary;
}

.closeIcon {
  margin-left: 4px;
  color: $red;
  font-size: 12px;
}

.inputOscarId {
  max-width: 125px;
}
