.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filterSection {
  @extend .form;
  gap: 16px;
  padding: 24px;
  padding-top: 2px;
}
