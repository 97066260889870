@import "styles/colours.scss";

.pageContent {
  display: grid;
  grid-template-columns: auto minmax(20vw, 45vw) auto;
  gap: 16px;
  background-color: $white;
  height: calc(100% - 57px);
}

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 48px;
}

.tableContainer {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 50vh;

  &::-webkit-scrollbar {
    width: 7px;
    float: right;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $placeholder-grey;
  }
}

.tagsContainer {
  padding-bottom: 48px;
}

.tag {
  display: flex;
  max-width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
}

.selectedItem {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 700;
  font-size: 14px;
}

.alert {
  max-width: 90%;
}
