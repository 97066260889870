@import "styles/colours.scss";

.dropdownLabelCore {
  padding: 0 8px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  height: 24px;
  line-height: 24px;

  i {
    margin-right: 8px;
    align-self: center;
  }
}

.notRelatedOption {
  composes: dropdownLabelCore;
  color: $white;
  background-color: $red;
}

.duplicateOption {
  composes: dropdownLabelCore;
  background-color: $mid-blue;
  color: $white;
}

.containsMultipleOption {
  composes: dropdownLabelCore;
  border: 1px solid $pagination-border;
  color: $grey;
}

.unsureOption {
  composes: dropdownLabelCore;
  border: 1px solid $red;
  color: $red;
}
