@import "styles/colours.scss";

.title {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 16px;
  margin: 0;
}

.lotTitle {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.lotsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.lot {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 3px 0 3px 24px;
  border-left: 2px solid #e6e7ea;
}

.propertyTitle {
  color: $text-secondary;
  min-width: 20%;
}
