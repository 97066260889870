@import "styles/ant_utils.scss";
@import "styles/colours.scss";
@import "styles/fonts.scss";

.drawer {
  font-family: $font-default;
}

.searchInput {
  font-size: 15px;
  color: $v2-text-secondary;

  .searchIcon {
    color: $link-blue-default;
  }
}

.titleBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  padding-bottom: 0px;

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0px;
    color: $v2-dark-blue;
  }
}

.contactFilters {
  padding: 0px 24px;
}

.subtitle {
  font-weight: 700;
  font-size: 20px;
  color: $v2-dark-blue;
  margin-bottom: 24px;
}

.clearFiltersBtn {
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  margin-left: 24px;

  &:hover {
    color: $mid-blue;
  }
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.inputLabel {
  font-weight: 500;
  font-size: 14px;
  color: $v2-dark-blue;
}

.subtitleLabel {
  margin-top: 2px;
  margin-bottom: 4px;
  font-size: 12px;
  color: $disabled-grey;
}

.contactCheckbox {
  // Needed to overwrite ant style
  margin: 8px 0px !important;
  font-family: inherit;
  font-size: 14px;
  color: $v2-dark-blue;
}
