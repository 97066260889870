@import "styles/colours.scss";

.buyerRelationshipPage {
  height: calc(100vh - 65px);
  max-height: calc(100vh - 65px);
  width: 100vw;
  padding: 16px;

  h1 {
    color: $colorPrimary;
    font-size: 24px;
    font-weight: 700;
  }

  .pageContent {
    background-color: $white;
    height: calc(100% - 57px);
  }

  .nav {
    position: fixed;
    padding: 8px;
    max-width: calc(100% - 32px);
  }
}
