.hierarchical {
  .relationshipsContainer {
    width: 100%;
  }

  .buttonContainer {
    display: flex;
    margin-top: 4px;

    button {
      margin-right: 8px;
    }
  }

  .hierarchyVisualisation {
    display: grid;
    grid-gap: 16px;

    justify-items: center;

    p {
      margin: 0;
      text-align: center;
      display: grid;
      grid-template-columns: min-content auto;
      grid-gap: 4px;
    }

    .findBuyerButton {
      grid-column-end: 3;
    }
  }
  .instructions {
    margin: 0;
  }
}
