@import "styles/colours.scss";

.blurred,
.blurred:hover {
  color: transparent !important;
  text-shadow: 0 0 10px $grey;
  letter-spacing: -1px;
  user-select: none;
  // we have the cursor because we want people to click and trigger the slideout
  cursor: pointer;

  &.link {
    text-shadow: 0 0 10px $link-blue-default;
  }
}

.tooltip > div {
  width: max-content;
}

.showHelperButton {
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
