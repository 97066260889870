@import "styles/colours.scss";
.popoverContent {
  max-height: 240px;
  overflow: scroll;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.popoverTitle {
  padding: 4px 4px 0 4px;
  margin-bottom: 0;
  font-size: 16px;
}

.orgProperty {
  color: $colorPrimary;
  cursor: pointer;
}

.orgProperty:hover {
  text-decoration: underline;
}

.link {
  font-weight: 400;
}
