@import "styles/colours.scss";

.divWrapper {
  padding: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: $light-grey;
}

.closeFile {
  float: right;
}

.linkInput {
  width: 70%;
  display: inline-block;
}

.field {
  margin-top: 16px;
  margin-bottom: 16px;
}
