@import "styles/colours.scss";

.chartBar {
  display: grid;
  font-size: 14px;
  line-height: 14px;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
}

.label {
  font-weight: bold;
}

.bar {
  height: 12px;
  border-radius: 4px;
}

.mid-blue {
  -webkit-print-color-adjust: exact;
  background-color: $mid-blue;
  @media print {
    background-color: $mid-blue;
  }
}

.light-blue {
  -webkit-print-color-adjust: exact;
  background-color: $light-blue;
  @media print {
    background-color: $light-blue;
  }
}
