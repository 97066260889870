@import "styles/colours.scss";

.documentForm {
  padding: 20px;
}

.errors {
  color: $red;
  margin-top: 10px;
  margin-bottom: 10px;
}

.error {
  display: block;
}

.label {
  display: block;
}

.field {
  margin-top: 10px;
  margin-bottom: 10px;
}

.required {
  color: $red;
  margin-left: 4px;
  margin-right: 4px;
}
