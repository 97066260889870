@import "styles/colours.scss";

.saveSupplierEmptyState {
  display: flex;
  gap: 24px;
  align-self: center;
  max-width: 80%;
  margin-top: 48px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: $grey700;

  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
  }

  p {
    margin: 0;
    font-weight: 500;
  }
}

.list {
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin: 0;
  padding-left: 12px;
  font-weight: 400;
}

.btnContainer {
  display: flex;
  gap: 8px;
}

.btnText {
  color: $grey700;
  font-weight: 500;
}
