.container {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
}

.tagPill {
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 30px;
}
