@import "styles/colours.scss";

.container {
  display: flex;
  flex-direction: column;

  min-width: 100%;

  border: 1px solid $grey50;
  border-radius: 4px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.trigger:hover {
  background-color: $sysBackgroundAlternative;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 16px;

  user-select: none;
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 1px;

  background-color: $grey50;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: 100%;
}

.title {
  margin: 0;

  color: $text-primary;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.subtitle {
  margin: 0;

  color: $text-secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.button {
  all: unset;

  padding: 0 16px;
}

.content {
  padding: 16px;
}
