@import "styles/colours";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 24px;
  height: 22px;
}

.closeIconWrapper {
  z-index: 200;

  &:hover {
    .closeIconDefault {
      display: none;
    }

    .closeIconHover {
      display: unset;
    }
  }
}

.closeIconHover {
  display: none;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 8px;

  svg {
    margin-top: -2px; // optical alignment
  }
}
