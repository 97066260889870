@import "styles/colours.scss";

.qualificationContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  flex-shrink: 0;
}

.greenCircleIcon {
  font-size: 20px;
  svg {
    fill: $colorSuccess;
  }
}

.selectContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}
