@import "styles/colours.scss";

.notificationContentTypes {
  display: grid;
  gap: 16px;
}

.spanRow {
  grid-column: 1 / -1;
}

.switchSet {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 16px;
}

.content {
  display: grid;
  gap: 24px;
}

.noOptionsMessage {
  font-size: 12px;
  line-height: 18px;
  color: $grey;
}
