@import "../../styles/colours";
@import "styles/ant_utils.scss";

$topMargin: 16px;

.feedPreviewOutputs {
  height: 100%;
  background-color: $white;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $colorBorder;

  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 16px;
    flex: 0 0;
  }

  .recordListContainer {
    @include no-scrollbar;
    flex: 1 1;
  }
}
