@import "styles/colours.scss";
.pdfViewer {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content max-content;
}

.viewerContainer {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  outline: none;
  grid-area: 2;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: $light-grey;
  :global(.pdfViewer) {
    :global(.page) {
      border: 0;
      margin: 1px auto 16px;
    }
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  z-index: 9999;
  cursor: default;
  padding: 8px;
  background-color: $white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  i {
    vertical-align: middle;
  }
}

.pageNavigation {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  justify-self: left;
}

.pageCount {
  justify-self: right;
}

.errors {
  color: $red;
}

.closeViewerCross {
  padding: 10px;
  cursor: pointer;
}

.findButtons {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
}
