.cpvMiniChart {
  display: grid;
  gap: 8px;
  break-inside: avoid;
  page-break-inside: avoid;
}

.chartBars {
  display: grid;
  gap: 6px;
}

.title {
  line-height: 17.5px;
}
