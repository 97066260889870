// Keep values in sync with constants.ts
$topbarHeight: 64px;

$sidebarWidthExpanded: 300px;

$contentHeight: calc(100vh - #{$topbarHeight});

$depressedShadow:
  inset 0 0 4px rgba(0, 0, 0, 0.05),
  inset 2px 2px 6px rgba(0, 0, 0, 0.05) !important;

$topBarZIndex: 999;

$previewHeaderHeight: 85px;
