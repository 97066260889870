@import "../filter_form/SharedFilterForm.module.scss";

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionTitle {
  font-weight: 700;
}

.filterSection {
  @extend .form;
  gap: 16px;
}

.formContent {
  @extend .formContent;
}
