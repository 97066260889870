@import "styles/colours";

.onboardingNavigation {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 32px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  background-color: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

  .stepsContainer {
    grid-column: 2;
    align-self: center;
    width: 130px;
  }

  .backButton {
    grid-column: 1;
    justify-self: start;
    align-self: center;
  }

  .skipButton {
    padding: 0;
    margin-right: 60px;
  }

  .nextContainer {
    grid-column: 3;
    justify-self: end;
    align-self: center;
  }

  .nextButton {
    grid-column: 3;
    justify-self: end;
    align-self: center;
    width: max-content;
    display: inline-block;
  }
}
