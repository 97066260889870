@import "styles/colours.scss";

.container {
  padding: 8px;
  background-color: $white;
}

.noComments {
  margin-bottom: 16px;
  font-size: 16px;
}
