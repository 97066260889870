@import "styles/colours.scss";
@import "styles/media_queries.scss";

.reportLayout {
  display: block;
  max-width: map-get($breakpoints, xl);

  background-color: $white;
  margin: 0 auto;
  padding: 0 64px 32px;
  -webkit-print-color-adjust: exact !important;
  @media print {
    padding: 0;
  }
}

.section {
  margin-bottom: 32px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  break-after: avoid;
  page-break-after: avoid;
}
