.content {
  white-space: pre-line;
  grid-column: 1 / span 2;
}

.icon {
  margin: auto;
}

.iconContainer {
  background: rgba(255, 233, 173, 0.2);
  border-radius: 4px;
  display: block;
  height: 26px;
  width: 26px;
  text-align: center;
}
