@import "styles/colours.scss";

// TODO: move to colours.scss
:export {
  lightBlue: $light-blue;
}

.infoIcon {
  font-size: 15px;
}

.label {
  margin-right: 8px;
}
