@import "styles/colours.scss";
@import "styles/fonts.scss";

.steps {
  margin-left: -6px;
}

.timelineTitle {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: horizontal;
}

.titleContainer {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 360px;
  max-width: max-content;
  gap: 16px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  color: $colorText;
}

.stageTitle {
  @extend .title;
  display: inline-flex;
  color: $colorTextTertiary;
  min-width: 150px;
  max-width: fit-content;
  gap: 4px;
}

.iconContainer {
  display: inline;
  vertical-align: middle;
}

.noticeTitle {
  @extend .title;
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.noInfo {
  color: $colorTextTertiary;
  display: inline-block;
  overflow: hidden;
  font-size: 14px;
}

.infoIcon {
  font-size: 12px;
  margin-left: 4px;
}

.datesContainer {
  display: flex;
  flex-direction: column;
  min-width: 360px;
  max-width: fit-content;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 8px;
}

.timelineDate {
  display: flex;
  align-items: center;
  gap: 16px;
}

.timelineDateTitle {
  color: $colorTextTertiary;
  min-width: 150px;
}

.smallText {
  color: $colorTextTertiary;
  font-size: 12px;
}

.prop {
  display: flex;
  gap: 6px;
  align-items: flex-end;
}

.empty {
  display: block;
  height: 16px;
}

.extensionWarning {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid $colorWarning;
  background-color: $light-orange-v2;
  font-size: 14px;
  margin-left: 4px;
  padding: 5px 12px;
  border-radius: 4px;
  color: $colorText;
}

.warningInfoIcon {
  font-size: 14px;
  margin-left: 4px;
  color: $colorWarning;
}
