@import "styles/colours.scss";
@import "styles/fonts.scss";

$planIconLineHeight: 9.6px;

.planIcon {
  background-color: $dark-blue;
  color: $white;
  font-family: $font-default;
  font-size: 8px;
  line-height: $planIconLineHeight;
  padding: 2px;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
}

.largePlanIcon {
  composes: planIcon;
  font-size: 12px;
  padding: 4px;
}

.proIcon {
  composes: planIcon;
  background-color: $v2-dark-blue;
}

.freeIcon {
  composes: planIcon;
  background-color: $green;
}

.betaIcon {
  composes: planIcon;
  background-color: $orange;
  color: $grey;
  padding: 4px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  margin-left: 8px;

  &.noMargin {
    margin: 0;
  }

  &.dark {
    background-color: #2d3b54;
    color: $white;
  }
}

.betaTag {
  width: 51px;
  height: 24px;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
  line-height: 15px;
  background: linear-gradient(90deg, $yellow400 0%, $yellow500 100%);
  font-weight: 500;
  font-size: 12px;
}

.newIcon {
  composes: planIcon;
  background-color: $orange;
  color: $grey;
  padding: 4px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  margin-left: 8px;

  &.noMargin {
    margin: 0;
  }

  &.dark {
    background-color: #2d3b54;
    color: $white;
  }
}
