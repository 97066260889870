@import "../../styles/colours.scss";

.resultsBar {
  padding: 13px 16px;
  border-bottom: 1px solid $light-grey;

  display: flex;

  .expander {
    flex: 1;
  }

  .exportButton {
    margin-left: 8px;
  }

  .resultsCount {
    margin-bottom: 0;
  }
}

// we want the search table to take up all available vertical space from the start
// then we will set `scroll.y` on ant's table to make sure the actual table fills that space
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.searchTable {
  flex: 1 1 100%;
  overflow: hidden;
}
