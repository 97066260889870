@import "styles/colours.scss";

.supplierSection {
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
  break-inside: avoid;
}
.suppliersTable {
  width: 100%;
  font-size: 16px;
  line-height: 125%;

  @media print {
    font-size: 12px;
  }
  td {
    padding: 16px 8px;
  }

  .contentRow {
    border-bottom: 1px solid $legacy-border-grey;
  }

  a,
  th {
    font-weight: 400;
    padding: 0 8px;
    font-size: inherit;
  }

  th {
    color: $dark-grey;
    white-space: nowrap;
  }
}
