@import "styles/colours.scss";

.cleanDuplicatesPage {
  background-color: $white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(0, 1fr);
  grid-gap: 8px;
  height: 100%;

  > div {
    padding: 8px;
    height: 100%;
    overflow-y: scroll;
  }

  .candidateCard {
    box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
    padding: 16px;
    border-radius: 4px;
    margin: 16px;

    .entityLabel {
      color: $grey;
      font-size: 20px;
      margin: 0;
    }
  }

  .hierarchyTypeButtons {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    width: max-content;
  }
}
