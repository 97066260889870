@import "styles/colours.scss";

.relevanceNumber {
  font-weight: bold;
  margin-left: 8px;
  font-size: 14px;
}

.relevanceScoreBtn {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 2px;
}

.oldPopoverTitle {
  display: flex;
  vertical-align: middle;
  margin: 12px;
  margin-bottom: 0;
  padding-bottom: 24px;
  border-bottom: 1px solid $grey50;
}

.popoverTitle {
  display: flex;
  align-items: center;
}

.popoverHeader {
  display: flex;
  margin: 12px;
  margin-bottom: 0;
  padding-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $grey50;
}

.container {
  margin: 12px;
  margin-top: 24px;
  overflow: auto;
  max-width: 500px;
}

.relevanceTitle {
  font-weight: bold;
  margin-left: 8px;
  font-size: 16px;
}

.externalLink {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.signalNameTitle {
  font-weight: bold;
  margin-bottom: 8px;
}

.signalsContainer {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  padding: 0;
}

.tagPill {
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 30px;
}

.settingsBtn {
  float: right;
  margin-top: 8px;
}

.loadingSpinner {
  display: inline-flex;
  margin: 8px;
  max-width: fit-content;
}

.borderLess {
  border: none;
  box-shadow: none;
}
