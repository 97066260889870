@import "styles/colours.scss";

.bookmark {
  &-red {
    fill: $red;
    stroke: $red;
  }

  &-blue {
    fill: $colorPrimary;
    stroke: $colorPrimary;
  }

  &-grey {
    fill: $grey;
    stroke: $grey;
  }
}

.empty {
  fill: $white;
}
