@import "styles/colours";

.tableFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ul {
    display: inline-block;
  }
}
