.form {
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 16px;
  justify-items: center;
}

.select {
  width: 300px;
}
