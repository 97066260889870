@import "styles/colours.scss";

.menuContent {
  width: 400px;
  border: none !important;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    float: right;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $placeholder-grey;
  }
}

.divider {
  margin-top: 12px;
  margin-bottom: 8px;
}

.searchbox {
  color: $v2-text-secondary;
  margin: 12px;
  margin-bottom: 0px;
  width: auto;
}

.searchIcon {
  color: $colorPrimary;
  margin-right: 2px;
}

.popoverContainer {
  display: flex;
  flex-direction: column;
  max-height: 350px;
}

.listHeader {
  font-size: 12px;
  color: $text-secondary;
  margin: 0;
}

.listItem {
  display: flex;
  margin: 0px;
  justify-content: space-between;
  align-items: center;
}

.createListBtn {
  padding: 8px;
  padding-right: 12px;
  margin: 12px 16px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  width: fit-content;

  .plusIcon {
    margin-left: 0px;
  }
}

.listName {
  color: $colorPrimary;
}
