.btnContainer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-right: 8px;
}

.editFrom {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
}

.externalLink {
  width: 14px;
  height: 14px;
}
