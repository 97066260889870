@import "styles/colours.scss";

.summary {
  td {
    vertical-align: top;
  }
}

.totals {
  display: grid;
  gap: 24px;
  margin-right: 80px;
  width: 256px;
  page-break-inside: avoid;
  break-inside: avoid;
}

.statsDetails {
  display: grid;
  gap: 8px;

  h3 {
    font-size: 14px;
    color: $dark-grey;
  }
}

.chart {
  display: grid;
  gap: 8px;
}

.valueContainer {
  display: grid;
  gap: 8px;
  align-items: center;
  grid-template-columns: max-content 1fr;
}

.value {
  font-size: 24px;
}

.unit {
  font-size: 16px;
  color: $transparent-dark-blue;
}

.square {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.mid-blue {
  -webkit-print-color-adjust: exact;
  background-color: $mid-blue;
  @media print {
    background-color: $mid-blue;
  }
}

.light-blue {
  -webkit-print-color-adjust: exact;
  background-color: $light-blue;
  @media print {
    background-color: $light-blue;
  }
}

.cpvCodeStats {
  display: block;
  > div {
    margin-bottom: 16px;
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
