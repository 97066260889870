@import "styles/colours.scss";

.sectionHeader {
  margin-bottom: 32px;

  break-inside: avoid;
  break-after: avoid;
  page-break-inside: avoid;
  page-break-after: avoid;
  @media print {
    margin-bottom: 16px;
  }
}

.sectionTitle {
  font-size: 28px;
  line-height: 125%;
  color: $dark-grey;
  font-weight: 700;
  margin-bottom: 8px;
  @media print {
    font-size: 22px;
  }
}

.sectionDescription {
  color: $grey;
  font-size: 14px;
  line-height: 125%;
  margin: 0;
  @media print {
    font-size: 12px;
  }
}
