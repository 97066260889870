@import "styles/colours.scss";

.validation {
  color: $red;
}

.heading {
  font-weight: bold;
  margin-bottom: 4px;
  display: block;
}

.formItem {
  margin: 8px;
}

.infoButton {
  margin-left: 4px;
}
