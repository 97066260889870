@import "styles/colours";

// borders
$border-radius-sm: 2px;
$border-radius-md: 4px;
$border-basic-light: 1px solid $light-grey2;
$border-basic-mid: 2px solid $light-grey2;

// page template
$default-page-border: 16px 24px;
$default-section-padding: 16px;
