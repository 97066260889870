@import "styles/colours.scss";
@import "styles/layouts";

.title {
  font-weight: 700;
  font-size: 24px;
  color: $text-primary-v2;
  margin-bottom: 0;
}

.supplierSearchPage {
  display: flex;
  flex-direction: column;
  overflow-y: visible;
}

.supplierSearchContent {
  flex-grow: 1;
  margin-top: 0;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  gap: 8px;
  margin-top: 16px;
}

.card {
  width: 240px;
  height: 60px;
  border: 1.5px solid $border-grey;
  border-radius: 4px;
  color: $text-primary-v2;

  h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: $grey700;
  }

  &:hover {
    background-color: $colorPrimaryBg;
    border: 1.5px solid $colorPrimary;
  }

  &.active {
    background-color: $colorPrimary;
    color: $white;
    border-radius: $border-radius-md;
    border: 1px solid $colorPrimaryBorder;

    h3 {
      color: inherit;
    }
  }
}

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paywallIcon {
  margin-left: 4px;
}
