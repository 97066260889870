@import "styles/colours.scss";

.stage {
  &-yellow {
    color: $sunrise-yellow;
  }

  &-gold {
    color: $calendula-gold;
  }

  &-green {
    color: $polar-green;
  }

  &-red {
    color: $volcano;
  }

  &-blue {
    color: $daybreak-blue;
  }

  &-unknown {
    color: $neutral-grey;
  }
}

.stageTooltip > div {
  width: max-content;
}

.paywallIcon {
  margin-left: 8px;
}
