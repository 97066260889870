@import "styles/colours.scss";
@import "styles/layouts";
@import "styles/fonts.scss";

.contactDetail {
  color: $link-blue-default;
  cursor: pointer;
}

.elipsisText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.contactDetailContent {
  @extend .elipsisText;
  word-break: break-all;
  overflow: hidden;
}

.rowHead {
  @extend .elipsisText;
  font-weight: 700;

  &:hover {
    text-decoration: none;
  }
}

.contactActions {
  display: inline-flex;
  gap: 8px;
}

.marginBottom {
  margin-bottom: 4px;
}

.contactCellContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.pointer {
  cursor: pointer;
}

.buyerNameCell {
  display: flex;
  align-items: center;
}

.emptyState {
  color: $disabled-grey;
}

.contactIcon {
  margin-right: 4px;
}

.buyerIcon {
  margin-right: 8px;
  border-radius: 2px;
}

.placeholderBuyerIcon {
  @extend .buyerIcon;
  font-size: 16px;
  color: $grey;
  background-color: $light-grey;
  padding: 4px;
}

.buyerLogoContainer {
  display: block;
}

.contactNameCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  height: 16px;
  width: 16px;
}

.emptyStateScreen {
  display: flex;
  height: 70vh;
  font-family: $font-default;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
}

.headerText {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: $v2-dark-blue;
}

.normalText {
  font-size: 14px;
  color: $v2-text-secondary;
  max-width: 400px;
  text-align: center;
}

.graphic {
  min-width: 100px;
  min-height: 100px;
  margin-bottom: 24px;
}

.outreachButtonDisabled {
  opacity: 0.3;
}

.actions {
  display: flex;
  gap: 8px;
}
