@import "styles/colours.scss";

.upperCard {
  background-color: #fafbfc;
  border: 1px solid $grey50;
  border-radius: 6px 6px 0 0;
}

.lowerCard {
  border-radius: 0 0 6px 6px;
  border: 1px solid $grey50;
  border-top: 0;
}

.borderRadius {
  border-radius: 6px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.qualificationContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.qualificationBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.caretDownIcon {
  font-size: 14px;
}

.customAlign {
  // to accommodate for Antd default padding
  column-gap: 19px !important;
}

.property {
  font-size: 14px;
  align-items: center;
  flex-wrap: wrap;
}

.mediumTitle {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
