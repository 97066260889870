@import "styles/colours.scss";

.property {
  font-size: 14px;
  word-break: break-word;
}

.sourceProperty {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 14px;
  align-items: center;
}

.sourcePropertyTitle {
  color: $text-secondary;
  min-width: 30%;
}

.linkEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $colorPrimary;
}

.exportIcon {
  color: $colorPrimary;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.relevanceScoreBtn {
  padding: 0;
}

.advancedProp {
  display: flex;
  gap: 16px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
