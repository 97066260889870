@import "styles/colours.scss";
@import "styles/layouts";

.results {
  display: flex;

  span {
    white-space: pre-wrap;
  }
}

.emailContent {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  width: 100%;

  .copyIcon {
    color: transparent;
  }

  &:hover {
    background-color: #3970e414;

    .copyIcon {
      color: $link-blue-default;
    }
  }
}

.resultsActions {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mailToBtn {
  padding: 0px 12px;
  padding-top: 2px;
  margin-left: 16px;
}

.setupHelp {
  width: 360px;
}

.blackBtn {
  color: inherit !important;
  font-weight: normal;
}
