@import "styles/fonts.scss";
@import "styles/colours.scss";

.banner {
  background-color: $sysPrimarySubtle;
  display: flex;
  width: calc(100% - 4px); // -4px to match table width
  justify-content: space-between;
  gap: 24px;
  padding: 24px 32px;
  align-items: center;
  border-bottom: 1px solid $grey50;
  border-left: 1px solid $grey50;
  border-right: 1px solid $grey50;
  border-radius: 0 0 4px 4px;

  h4 {
    font-size: 16px;
    font-weight: 700;
    color: $sysTextDefault;
    margin-bottom: 0;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.description {
  font-size: 14px;
  color: $sysTextSecondary;
}
