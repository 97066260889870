@mixin no-text-wrap {
  white-space: nowrap;
}

@mixin non-clickable-cell {
  cursor: default !important;
}

:global .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  padding-bottom: 0px !important;
}

.valueColumn {
  @include no-text-wrap;
}

.stageColumn {
  @include no-text-wrap;
}

.tagColumn {
  @include non-clickable-cell;
}

.actionColumn {
  @include non-clickable-cell;

  button {
    color: black;
    padding: 2px 8px;
  }

  & > div {
    display: flex;
    align-items: center;
  }
}

.relevanceColumn {
  @include no-text-wrap;
}
