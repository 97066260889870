@import "styles/colours.scss";

.text {
  color: $dark-blue;
  // important required to override some global styles 🙄
  ul {
    padding-left: 20px !important;
    list-style: disc !important;
  }
}
