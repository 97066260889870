@import "styles/colours.scss";

.exportModal {
  display: grid;
  grid-template-columns: min-content auto;
  grid-column-gap: 8px;
  grid-row-gap: 22px;

  .content {
    display: grid;
    grid-gap: 22px;
  }

  h1 {
    font-size: 16px;
    line-height: 22px;
    color: $dark-grey;
    font-weight: 700;
    margin: 0;
  }

  p,
  b {
    color: $dark-grey;
    font-size: 14px;
    margin: 0;
  }

  button {
    grid-column-start: 2;
    justify-self: end;
    width: max-content;
  }
}

.errorIcon {
  color: $colorError;
  font-size: 16px;
  margin-top: 2px;
}
