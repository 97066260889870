@import "styles/colours.scss";
@import "styles/media_queries.scss";

.bookADemoPaywallCTAModal,
.subscriptionPaywallCtaModal {
  min-width: calc(100vw - 60px);
  max-height: calc(100vh - 26px);
  margin-top: 24px;
  border-radius: 8px 8px 0px 0px;
}

@media (max-width: map-get($breakpoints, xl)) {
  .subscriptionPaywallCtaModal,
  .bookADemoPaywallCTAModal {
    border-radius: 8px;
  }
}

.subscriptionPaywallCtaModal {
  .subscriptionModalContent {
    border-radius: 8px 8px 0 0;
  }

  @media (max-width: map-get($breakpoints, xl)) {
    .subscriptionModalContent {
      border-radius: 8px;
    }
  }
}

.bookADemoPaywallCTAModal {
  overflow: hidden;

  .bookADemoModalContent {
    border-radius: 8px 0px 0px 8px;
    padding: 0px;
    height: 100%;
  }

  @media (max-width: map-get($breakpoints, xl)) {
    .bookADemoModalContent {
      border-radius: 8px;
    }
  }
}
