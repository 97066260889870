@import "styles/colours.scss";

.actionableBanner {
  margin-top: 32px;
  background: linear-gradient(270deg, $mid-blue 0%, $sysPrimaryDefault 100%);
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .backgroundGeosRight,
  .backgroundGeosLeft {
    position: absolute;
    top: 0;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .backgroundGeosLeft {
    left: 0;
    width: auto;
  }

  .backgroundGeosRight {
    right: 0;
    width: auto;
  }

  .bannerContent {
    display: flex;
    padding: 96px 136px;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    padding: 96px 136px;
    text-align: center;
    z-index: 2;

    h1 {
      align-self: stretch;
      color: #fff;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 0px;
    }
  }

  .bookADemoButton {
    background-color: white;
    color: $sysPrimaryDefault;
    z-index: 3;
  }
}
