@import "../../../styles/colours.scss";

.overflowPopover {
  max-height: 400px;
  overflow-y: auto;

  :global(.ant-popover-title) {
    padding: 16px;
  }
}

.contactDetail {
  color: $sysPrimaryDefault;
  display: block;
  cursor: pointer;
}

.blurred,
.blurred:hover {
  color: transparent !important;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  letter-spacing: -3px;
  user-select: none;
}
