@import "styles/colours.scss";

.textButton {
  height: auto;
  padding: 0;
  &:hover {
    .underline {
      text-decoration: underline;
    }
  }
}

.bold {
  font-weight: 700;
}

.inheritSize {
  font-size: inherit;
}
