@import "styles/colours.scss";
@import "styles/constants.scss";

.planDetailsSection {
  .oldPlanNames {
    display: grid;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    grid-template-columns: 36.7% 19% 19% 19%;
    gap: 0px 32px;

    .oldPlan {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      text-align: center;
    }
  }

  .description {
    padding-top: 64px;
    padding-bottom: 64px;

    h1 {
      font-size: 32px;
      font-weight: 700;
      line-height: 100%;
      color: $colorTextBase;
      margin-bottom: 8px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0px;
      color: $sysTextSecondary;
    }
  }

  .planComparisons {
    display: grid;
    gap: 24px;
  }
}

.textWithIcon {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .text {
    display: flex;
    align-items: center;
    color: $seo-dark-grey;
  }
}

.includedIcon {
  color: $colorPrimary;

  svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
}

.excludedIcon {
  color: red;

  svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
}

.addonIcon {
  color: $sysTextInactive;

  svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
}

.dot {
  color: $blue400;
}

.planIcon {
  width: 16px;
  height: 16px;
}

.oldBillingInfo {
  font-size: 12px;
  font-weight: 400;
  color: $sysTextSecondary;
}

.planTable {
  width: 100%;
  table-layout: fixed;

  .planHeader {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 1;
  }

  .featureNameHeaderCell {
    width: 36%; // This + (.planHeaderCell * 4) = 100%

    // Doing so table works on smaller screens + don't need to calculate top value offset until it gets even smaller
    @media screen and (max-width: calc($sidebarWidthExpanded + 1250px)) {
      width: 20%;
    }
  }

  .planHeaderCell {
    width: 16%;
    padding-top: 16px;
    padding-bottom: 8px; // Only here to ensure there is no gap between the two sticky headers

    @media screen and (max-width: calc($sidebarWidthExpanded + 1250px)) {
      width: 20%;
    }
  }

  .planNames {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;

    @media screen and (max-width: calc($sidebarWidthExpanded + 1250px)) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .billingInfo {
    font-size: 12px;
    font-weight: 400;
    color: $sysTextSecondary;

    // Keep in sync with PlanComparisonTable's .headerRow
    @media screen and (max-width: calc($sidebarWidthExpanded + 920px)) {
      min-height: 48px;
    }
    @media screen and (max-width: calc($sidebarWidthExpanded + 585px)) {
      min-height: 72px;
    }
  }
}
