@import "styles/colours.scss";

.contentRoot {
  min-height: 100%;
}

.pageLayout:global(.ant-layout) {
  min-height: 100vh;

  &.fullHeightLayout {
    height: 100vh;
  }

  & :global(.ant-layout-footer) {
    font-size: 16px;
    padding-left: 32px;
    padding-right: 32px;
  }

  & :global(.ant-layout-footer) a {
    color: #666666;
  }
}

.reactQuery {
  left: 50% !important;
  font-size: 1em !important;
  position: absolute !important;
  svg {
    display: none;
  }
  span {
    height: 20px !important;
    width: 40px !important;
    font-size: 13px;
    height: 20px !important;
    width: 128px !important;
    color: white;
  }
}

.errorBoundaryRoot {
  height: 100vh;
}
