@import "../../styles/colours";

.overlay {
  box-shadow:
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  max-height: 400px;
  min-width: 220px;
  background-color: $white;
  overflow-y: auto;
}

.list {
  display: grid;
  gap: 2px;
  padding: 12px;
  list-style: none;
  margin: 0;
  background-color: $white;
  border-radius: 2px;
}

.listItem {
  background-color: $white;
  font-size: 14px;
  line-height: 22px;
  color: $dark-grey;
}

.selectAll {
  @extend .listItem;

  padding: 12px 12px 0 12px;
}

.input {
  margin-top: 8px;
  font-size: 14px;
  line-height: 22px;
  color: $dark-grey;
  i {
    font-size: 12px;
  }
}

.inputContainer {
  padding: 12px;
  .input {
    margin-top: 0;
  }
}

.optionWrapper {
  display: flex;
  align-items: center;
  column-gap: 4;
  width: 100%;
}

.description {
  margin-bottom: 0;
}
