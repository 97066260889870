@import "styles/colours";

body {
  :global(.tag-grey) {
    color: rgba(0, 0, 0, 0.75);
    background-color: $mid-grey;
    border-color: $mid-grey;
  }

  :global(.tag-default) {
    color: rgba(0, 0, 0, 0.75);
    background-color: $sysSignalDefault;
    border-color: $sysSignalDefault;
  }

  :global(.tag-pink) {
    color: rgba(0, 0, 0, 0.75);
    background-color: $pink;
    border-color: $pink;
  }

  :global(.tag-red) {
    color: rgba(0, 0, 0, 0.75);
    background-color: $sysSignalCompetitor;
    border-color: $sysSignalCompetitor;
  }

  :global(.tag-brown) {
    color: rgba(0, 0, 0, 0.75);
    background-color: $light-brown;
    border-color: $light-brown;
  }

  :global(.tag-orange) {
    color: rgba(0, 0, 0, 0.75);
    background-color: $orange;
    border-color: $orange;
  }

  :global(.tag-yellow) {
    color: rgba(0, 0, 0, 0.75);
    background-color: $gold200;
    border-color: $gold200;
  }

  :global(.tag-green) {
    color: rgba(0, 0, 0, 0.75);
    background-color: $light-green;
    border-color: $light-green;
  }

  :global(.tag-blue) {
    color: rgba(0, 0, 0, 0.75);
    background-color: $sysSignalKeyword;
    border-color: $blue200;
  }

  :global(.tag-purple) {
    color: rgba(0, 0, 0, 0.75);
    background-color: $purple200;
    border-color: $purple200;
  }

  :global(.tag) {
    padding: 2px 4px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
  }
}
