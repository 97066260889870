@import "../../styles/constants.scss";
@import "../../styles/colours";
@import "../../styles/media_queries.scss";
@import "../../styles/fonts.scss";

$layout-header-bottom-border: $pale-blue;
$selected-menu-item-underline: $link-blue-default;

.itemGroupTitle {
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 14px 0 8px;
}

.submenuDownIcon {
  color: $v2-text-secondary;
  margin-left: 4px;
}

.navMenu {
  width: 95%;
  line-height: $topbarHeight - 1;
  margin-bottom: 1px;

  > li {
    height: $topbarHeight;
    top: 0;
  }

  @include media-mobile() {
    max-width: 60vw;
  }

  // here we just want a little more specificty than media-mobile can provide
  @media (max-width: 420px) {
    max-width: 50vw;
  }

  .topLevelMenuItem {
    top: 1px;
    padding: 0px 16px;
    margin: 0;
    border-bottom: none;
    font-size: 16px;

    & > div {
      padding: 0;
    }

    &::after {
      position: absolute;
      width: 0;
      bottom: 0;
      height: 2px;
      background: $selected-menu-item-underline;
      display: block;
      left: 50%;
      content: "";
    }

    a,
    > div {
      color: $dark-grey;
    }

    &:hover {
      color: $dark-grey;
      background-color: $light-cyan;
    }
  }

  & > :global(.ant-menu-submenu):hover,
  > :global(.ant-menu-submenu-selected),
  > :global(.ant-menu-submenu-active),
  > :global(.ant-menu-submenu-open) {
    border-bottom-color: $selected-menu-item-underline;
    color: $dark-grey;
  }

  & > :global(.ant-menu-item.ant-menu-item-selected),
  > :global(.ant-menu-submenu-selected) {
    border-bottom: none !important;

    a,
    > div {
      color: $dark-grey;
    }

    &::after {
      width: 100%;
      left: 0;
    }
  }

  & > :global(.ant-menu-item.ant-menu-item-active:not(.ant-menu-item-selected)),
  & > :global(.ant-menu-submenu.ant-menu-submenu-active:not(.ant-menu-submenu-selected)) {
    border-bottom: none !important;

    a,
    > div {
      color: $dark-grey;
    }

    &::after {
      width: 100%;
      left: 0;
    }
  }
}

.hideIconOnOverflow {
  @extend .topLevelMenuItem;

  .submenuDownIcon {
    display: none;
  }
}

.submenuItem {
  display: grid;
  white-space: normal;
  word-wrap: break-word;

  // Need to use important css selectors,
  // otherwise it's a messy global style change for antd
  width: 240px !important;
  max-height: fit-content;

  // Also using fit-content so height is dynamic & screen responsive.
  height: fit-content !important;

  // Also works for firefox
  height: -moz-max-content !important;
  margin: 0px !important;
  padding: 4px !important;

  .menuItemContent {
    padding: 12px;

    &:hover {
      background-color: $light-cyan;
    }

    .menuItemTitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: $link-blue-default;
    }

    .menuItemDesc {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $v2-text-secondary;
    }
  }
}

.menuItemLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topLevelLabel {
  display: flex;
  gap: 8px;
  align-items: center;
}
