@import "styles/colours.scss";

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contactDeets {
  display: grid;
  grid-template-columns: auto 1fr;
  max-width: fit-content;
  grid-auto-flow: row;
  gap: 16px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  color: $text-primary;
  text-align: left;
  margin: 0;
}

.detail {
  color: $text-secondary;
}

.link {
  color: $colorPrimary;
}

.value {
  word-break: break-word;
}
