@import "styles/colours.scss";

.saveRecordMenu {
  width: 266px;
}

.scrollLists {
  max-height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $placeholder-grey;
  }
}

.userListTitle {
  font-size: 14px;
  line-height: 22px;
  margin-top: 16px;
  color: $grey;
}

.userList {
  list-style: none;
  padding: 0px;
  color: $link-blue-default;
  font-size: 12px;
  line-height: 18px;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  padding-right: 5px;
}

.userListItem {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  cursor: pointer;
  box-shadow: inset 0px -1px 0px $border-grey;
}

.listName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.actionContainer {
  display: flex;
  margin-left: 4px;
  align-items: center;

  button {
    height: 22px;
    font-size: 10px;
    padding: 0 8px;
  }

  i {
    font-size: 11px;
  }
}

.menuItem {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.popover {
  width: 100%;
  display: block;
}

.savedButton {
  border-color: $v-green !important;
  color: $v-green !important;
}

.noList {
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  display: block;
  padding: 10px 8px;
  box-shadow: inset 0px -1px 0px $border-grey;
  margin-bottom: 16px;
}
