// Media queries breakpoints matching ant-design's breakpoints
$breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

// TODO: add more flexible breakpoint mixins

@mixin media-mobile {
  @media (max-width: map-get($breakpoints, lg) - 1) {
    @content;
  }
}

@mixin media-not-mobile {
  @media (min-width: map-get($breakpoints, lg)) {
    @content;
  }
}
