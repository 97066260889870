@import "styles/colours.scss";

.distinctJourney {
  .listContainer {
    padding: 16px;
    background-color: $app-bg-grey;

    ul {
      margin: 0 0 -8px 0;
      padding: 0;
    }

    li {
      background-color: $white;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }
  }

  .buttonContainer {
    margin: 16px 0;
    display: grid;
    grid-template-columns: min-content auto 1fr;
    align-items: center;
    grid-gap: 16px;
    width: max-content;
  }
}
