@import "styles/colours.scss";

.recordListHeading {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .recordListCount {
    font-size: 18px;
    color: $dark-blue;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
  }

  .cautionMessage {
    color: $dark-orange;
    text-transform: uppercase;
    border: 1px solid $light-orange;
    padding: 2px 4px;
    margin-left: 8px;
    font-size: 10px;
    display: inline-block;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    cursor: pointer;
  }

  .loadingSpinner {
    margin-left: 8px;
  }

  .filterRow {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .currentResults {
    margin: 0;
    color: $dark-grey;
    font-size: 12px;
    line-height: 18px;
  }
}
.cautionPopup {
  max-width: 450px;
  font-size: 12px;
}
