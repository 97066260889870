@import "styles/layouts";
@import "styles/fonts";

.paidTag {
  border-radius: 4px;
  background-color: rgba(255, 233, 173, 0.4);
  display: flex;
  padding: 4px 8px;
  gap: 8px;
  max-width: fit-content;
  font-weight: 700;
  font-size: 12px;
  font-family: $font-default;
}

.imgContainer {
  display: flex;
  justify-content: center;
  border-radius: 4px;
}

.bannerImg {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.modalImg {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.blueStotlesBackground {
  @extend .imgContainer;
  background: radial-gradient(farthest-corner at 100% -150%, #9bb9fa 71%, #d7e3fd 20%);
}

.yellowStotlesBackground {
  @extend .imgContainer;
  background: radial-gradient(farthest-corner at -50% 140%, #ffe59d 75%, #fff9e6 50%);
}

.redStotlesBackground {
  @extend .imgContainer;
  background: radial-gradient(farthest-corner at 130% 200%, #feafb6 75%, #ffd5d8 50%);
}

.purpleStotlesBackground {
  @extend .imgContainer;
  background: radial-gradient(farthest-corner at -5% -200%, #9586cd 72%, #c9bee6 50%);
}

.img {
  object-fit: scale-down;
  max-width: 75%;
}

.imgLarge {
  object-fit: contain;
  max-width: 65%;
}

.videoContainer {
  display: flex;
  justify-content: center;
  border-radius: 4px;
}

.video {
  border-radius: 4px;
  max-width: 100%;
}
