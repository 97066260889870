@import "styles/colours.scss";

.filterPill {
  border-radius: 11px;
  font-size: 12px;
  line-height: 18px;
  padding: 2px 8px;
  height: 23px;
}

.selected {
  background-color: $sysPrimaryDefault;
  color: $white;
  border: 1px solid $sysPrimaryDefault;

  &:focus,
  &:hover {
    color: $white;
    background-color: $sysPrimaryDefault;
  }
}
.active {
  background-color: $white;
  color: $sysPrimaryDefault;
  border: 1px solid $sysPrimaryDefault;
}
.disabled {
  background-color: $white !important;
  color: $placeholder-grey !important;
  border: 1px solid $placeholder-grey !important;
}
