@import "styles/colours.scss";

.searchInput {
  width: 320px;
  margin-bottom: 8px;
}
.heading {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
}

.title {
  margin: 0;
}

.subHead {
  font-size: 14px;
  font-weight: 550;
  color: $blue800;
}

.orgName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 700;
}

.url {
  display: flex;
  align-items: center;
}

.link {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.filtersBar {
  display: flex;
  align-items: center;
  gap: 16px;
}
