@import "styles/fonts.scss";
@import "styles/colours.scss";

.inputGroup {
  .inputLabel {
    font-weight: 500;
    font-size: 14px;
    color: $v2-dark-blue;
  }
}

.disabledPopup {
  padding-top: 0px;
}

.disabledPopupHead {
  font-family: $font-default;
  font-weight: 700;
  margin-bottom: 0;
}

.disabledPopupBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px;
}

.disabledPopupTxt {
  color: $v2-text-secondary;
}
