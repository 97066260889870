@import "styles/colours.scss";

.collapseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: $dark-blue;
  margin-bottom: 0;
  font-weight: 400;
  &.small {
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
  }
}
