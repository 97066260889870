@import "styles/fonts";
@import "styles/media_queries";
@import "styles/colours";
@import "styles/layouts";

@mixin bottom-spacing {
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0px;
  }
}

.sectionTitleContent {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  .titleSpacer {
    flex: 1;
  }
}

.sectionTitleContentWithTabs {
  padding: 16px 16px 0;
  display: flex;
  align-items: center;
  gap: 16px;

  .titleSpacer {
    flex: 1;
  }
}

.sectionTitleBorder {
  border-bottom: 1px solid #ddd;
}

.sectionContentPadding {
  padding: $default-section-padding;
}

.detailsRow {
  margin-bottom: 16px;
  @include bottom-spacing;

  display: grid;
  grid-gap: 16px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: stretch;

  @include media-mobile {
    grid-auto-flow: row;
    grid-template-columns: 1fr !important; // we need to override the value set on the element from JS
  }
}

.sectionContainer {
  background-color: $white;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid $light-grey2;
  border-radius: 4px;
  box-sizing: border-box;
}

.sectionNoBorder {
  border: none;
}

.sectionContent {
  flex: 1 1 auto;
}

.page {
  flex: 1;
  min-width: 0;
  background-color: $white;
  font-family: $font-default;

  * {
    font-family: inherit;
  }

  :global(.ant-tabs) {
    background-color: #fff;
  }
}

.content {
  padding: $default-page-border;
}

.contentCard {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border: $border-basic-light;
  margin: $default-page-border;
  padding: $default-section-padding;
}

.detailsHeader {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 24px;
  padding-bottom: 8px;
  font-family: $font-default;
}

.borderBottom {
  border-bottom: $border-basic-light;
  padding-bottom: 16px;
}
