.frameworkCallOff {
  text-align: left;
}

.callOffCount {
  font-weight: 500;
  margin-bottom: 4px;
}

.callOffValue {
  font-size: 12px;
}
