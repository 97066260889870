@import "styles/colours.scss";

.page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100%;
}

.buyerRelationshipContent {
  padding: 16px;
  border: 1px solid $border-grey;
  min-height: 100%;
  padding-bottom: 64px;

  h2 {
    color: $colorPrimary;
    font-size: 20px;
    font-weight: 700;
  }

  h3 {
    color: $dark-blue;
    font-size: 18px;
    margin: 8px;
    font-weight: 700;
  }
}

.listContainer {
  background-color: $app-bg-grey;
  padding: 16px;
  flex: 1 1 auto;
  overflow: auto;

  ul {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    height: 100%;
  }

  li {
    background-color: $white;
    padding: 16px;
    display: grid;
    grid-template-columns: auto min-content;
    margin-bottom: 8px;
    grid-gap: 8px;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
  -webkit-box-orient: vertical;
  font-weight: 600;
}

.oscarIdText {
  font-weight: 600;
  color: $dark-blue;
}

.orgPreview {
  height: max-content;
}

.formState {
  margin-top: 16px;
}
