@import "styles/colours.scss";

.previewWrapper {
  display: flex;
}

.controls {
  display: flex;
  flex-basis: 64px;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-right: 1px solid $grey50;
  position: sticky;
  top: 0;
  height: 100vh;
}

.closeIcon {
  color: $colorPrimaryText;
}

.controlsBtn {
  border-color: $grey50;
  position: sticky;
  top: 16px;
}

.previewContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
}

.contentContainer {
  padding: 24px;
  display: flex;
  justify-content: center;
}

.columnsContainer {
  gap: 48px;
}

.header {
  display: flex;
  gap: 16px;
  padding: 24px;
  justify-content: center;
  border-bottom: 1px solid $grey50;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 1000;
}

.scroll {
  box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.12);
}

.loadingSkeleton {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
}
