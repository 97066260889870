@import "styles/colours.scss";
@import "styles/fonts.scss";

.modal {
  max-width: 450px;
  max-height: 350px;
}

.tourModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.largeIcon {
  max-height: 150px;
  max-width: 160px;
}

.icon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
  margin-left: 4px;
}

.modalTitle {
  font-family: $font-default;
  font-size: 20px;
  font-weight: 700;
  color: $text-primary-v2;
  margin-bottom: 0;
}

.modalDesc {
  color: $v2-text-secondary;
  text-align: center;
  margin-bottom: 0;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 8px;
}

.viewBtn {
  display: flex;
  align-items: center;
  padding-left: 4px;
}
