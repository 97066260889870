.searchInput {
  min-width: 260px;
  max-width: 320px;
  margin-top: 8px;
}

.loadingSkeleton {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
