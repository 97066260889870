@import "styles/colours";
@import "styles/layouts";
@import "styles/fonts";

.upgradeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  color: $text-primary-v2;
  border-right: $border-basic-light;
}

.upgradeContent {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 16px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.txt {
  margin: 0;
}

.bullets {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 32px;
}

.btnContainer {
  display: flex;
  gap: 8px;
  font-weight: 500;
}

.btn {
  display: flex;
  align-items: center;
}

.planText {
  display: flex;
  font-weight: 500;
  gap: 12px;
  margin: 4px 0px;
}

.planIcon {
  height: 20px;
  width: 20px;
}
