.content {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.gotItButton {
  margin-top: 8px;
  align-self: flex-end;
}
