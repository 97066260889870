@import "styles/colours";

.adminBar {
  width: 100%;
  background-color: $blue400;
  border-bottom: 1px solid $yellow400;
  color: $grey0;
  position: sticky;
  z-index: 999;
  top: 0;
  display: none;
  align-items: center;
  font-family: Inter, Lato, sans-serif;
}

.show {
  display: flex;
}

.adminProd {
  background-color: $blue400;
}

.adminSandbox {
  background-color: #ff6700;
}

.adminDev {
  background-color: #29d4b1;
}

.adminBar a:link,
.adminBar a:active,
.adminBar a:hover,
.adminBar a:visited,
.adminBar a:visited:hover {
  color: white;
}

.adminBar a {
  text-decoration: underline;
}

.adminBarProfile {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.adminBarClose {
  background-color: inherit;
  cursor: pointer;
  border-width: 0;
  margin-top: -2px;
  padding: 4px 8px;
  font-size: 1.2em;
  flex-shrink: 0;
}

.adminBarContent {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;
}

.adminButton {
  padding: 4px 12px;
  display: block;
  align-self: stretch;
  font-weight: bold;
}

.adminButtonDev {
  background-color: $blue900;
}

.adminButtonSandbox {
  background-color: $yellow500;
}

.adminButtonProd {
  background-color: #29d4b1;
}

.adminBar a.adminButton {
  text-decoration: none;
}

.layerCake {
  padding: 4px 12px;
  display: block;
  align-self: stretch;
  font-weight: bold;
  background-color: palevioletred;
}

@media print {
  .adminBar {
    display: none !important;
  }
}

// Ensuring the devcycle main iframe stays on top of everything and the button is on top of it
iframe[id="devcycle-debugger-iframe"] {
  z-index: 1000;
}

// Ensuring the devcycle button iframe stays on top of the main iframe
iframe[id="devcycle-debugger-button-iframe"] {
  z-index: 1001 !important;
}
