@import "styles/colours.scss";

.emptyStateDropdown {
  background-color: $sysPrimarySubtle;
  border-radius: 2px;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
}

.emptyStateTextContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.signalSettingsBtn {
  cursor: pointer;
  color: $colorPrimary;
  width: fit-content;
}

.signalSettingsBtnContents {
  color: $white;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}
