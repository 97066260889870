@import "styles/colours.scss";

.reminderTimeInputs {
  display: flex;
  gap: 4px;
}

.reminderInput {
  width: 90px;
  margin-top: 4px;
}

.reminderSelect {
  width: 90px;
}

.error {
  display: flex;
  gap: 4px;
  color: $red500;
  font-size: 12px;
  margin-top: 4px;
}

.footer {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 16px;
}
