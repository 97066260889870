@import "styles/colours.scss";

.stage {
  display: flex;
  gap: 8px;
}

.date {
  display: flex;
  gap: 8px;
  color: $secondary-text-grey;
}

.headerColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
}

.recordHeaderContent {
  display: flex;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  padding-right: 8px;
  vertical-align: middle;
}

.seenBy {
  font-size: 12px;
  border: 1px solid $grey50;
  border-radius: 4px;
  display: inline-block;
  padding: 3px 6px 3px 4px;
  vertical-align: middle;
}

.actions {
  align-items: center;
  display: flex;
  gap: 8px;
}

.crmContainer {
  display: flex;
  flex: 1;
  height: 100%;
}

@media screen and (max-width: 425px) {
  .recordHeaderContent {
    flex-direction: column;
    gap: 8px;
  }
}
