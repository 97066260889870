@import "styles/colours.scss";

.triggerButton {
  background-color: transparent;
  color: inherit;
  border: none;
  padding: none;
  margin: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
