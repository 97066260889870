@import "styles/colours.scss";
@import "styles/fonts.scss";

.modal {
  max-width: 450px;
  max-height: 300px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.modalTitle {
  font-family: $font-default;
  font-size: 20px;
  font-weight: 700;
  color: $text-primary-v2;
  margin-bottom: 0;
  text-align: center;
}

.modalDesc {
  color: $v2-text-secondary;
  text-align: center;
  margin-bottom: 0;
}

.btn {
  padding: 5px 8px;
}

.icon {
  font-size: 16px;
}

.likeIcon {
  @extend .icon;
  color: $white;
}

.dislikeIcon {
  @extend .icon;
  color: $colorPrimary;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  gap: 8px;
}

.userFeedback {
  width: 100%;
}
