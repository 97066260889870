@import "styles/colours";

.options {
  position: relative;
  list-style: square;
  display: none;
  max-height: 320px;
  overflow-y: auto;
  border: 0.0001em $light-grey;
  box-shadow:
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 100%;
  top: calc(100% + 4px);
  background-color: $white;
}

.options.show {
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
}

.filterDescription {
  border: 0px 0px 1px 0px;
  border-color: $grey50;
  background-color: rgba(23, 31, 44, 0.04);
  margin: 0px;
  min-height: 40px;
  max-height: 40px;
}

.filterDescriptionText {
  color: $sysTextPlaceholder;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 8px;
  gap: 16px;
}
