@import "styles/colours";

.labelAndIcon {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  min-height: 32px;
  gap: 8px;
  padding: 4px;
}

.labelAndIcon:hover {
  background-color: $colorPrimaryBg;
  border-radius: 4px;
}

.label {
  margin-left: 4px;
}

.labelBody {
  gap: 0;
}

.labelText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $sysTextDefault;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.countryText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $sysTextSecondary;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.signalIcon {
  margin-right: 4px;
  color: $colorPrimary;
  min-width: 16px;
}
