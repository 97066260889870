@import "styles/fonts.scss";

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0;
  }
}

.title {
  font-weight: 700;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.divider {
  margin: 16px 0px;
}

.actions {
  display: flex;
  gap: 8px;
}

.modal {
  display: grid;
  gap: 16px;
  font-family: $font-default;
}

.table {
  width: 952px;
  overflow-x: hidden;
}
