@import "styles/colours.scss";

.pageContent {
  display: flex;
  background: $white;
  overflow-x: auto;
}

.verticalNavLayout {
  display: flex;
  height: 100vh;
}
