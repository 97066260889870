@import "styles/colours.scss";
@import "styles/layouts";
@import "styles/fonts.scss";

.filtersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px;
  margin-bottom: 16px;
}

.filtersWithMargin {
  margin: $default-page-border;
  margin-bottom: 8px;
}

.contactFilters {
  display: inherit;
  gap: 8px;
}

.icon {
  color: $colorPrimary;
}

.searchInput {
  width: 250px;
}

.clearFilters {
  padding-left: 8px;
}

.allFilterBtn {
  align-items: center;
  display: inline-flex;
}
