.oscarSearchWidget {
  display: grid;
  grid-gap: 8px;
  // These two properties are so that the widget can be within a container
  // And the grid doesn't blow out over its parent
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;

  h1 {
    font-size: 20px;
    margin-bottom: 16px;
  }
}
