@import "styles/colours.scss";

.userInitialsContainer {
  height: 32px;
  width: 32px;
  font-size: 14px;
  line-height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &.small {
    height: 26px;
    width: 26px;
    font-size: 12px;
    line-height: 26px;
  }

  &.assigned {
    &.turquoise {
      background-color: $green;
    }
    &.yellow {
      background-color: $mustard-yellow;
    }
    &.blue {
      background-color: $sea-blue;
    }
    &.dark-blue {
      background-color: $picton-blue;
    }
    &.green {
      background-color: $grass-green;
    }
    &.gold {
      background-color: $nugget-gold;
    }
    &.orange {
      background-color: $christmas-orange;
    }
    &.light-green {
      background-color: $shamrock;
    }
    &.pink {
      background-color: $hot-pink;
    }
    &.red {
      background-color: $dark-red;
    }

    span {
      color: $white;
    }
  }

  &.unassigned {
    background-color: $light-grey;
    i {
      color: $dark-grey;
      font-size: 14px;
      line-height: 32px;
    }
    &.small i {
      font-size: 12px;
      line-height: 26px;
    }
  }
}
