@import "styles/colours.scss";

.upperCard {
  background-color: #fafbfc;
  border: 1px solid $grey50;
  border-radius: 6px 6px 0 0;
}

.upperCardContent {
  display: flex;
  gap: 16px;
  align-items: center;
}

.lowerCard {
  border-radius: 0 0 6px 6px;
  border: 1px solid $grey50;
  border-top: 0;
}

.lowerCardContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mediumTitle {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
