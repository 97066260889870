@import "styles/colours.scss";

.toolbar {
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 15px;
  background-color: $white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.spinner {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 32px;
  justify-items: center;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  line-height: 18px;
}
