@import "../../styles/colours.scss";

.bell {
  cursor: pointer;
  font-size: 18px;
}

.notification {
  display: flex;
  flex-direction: column;
  &:hover .markAsRead {
    display: block;
  }
}

.notificationHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 50px;
}

.notificationContent {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 18px;
  color: inherit;
}

.markAsRead {
  display: none;
  font-size: 12px;
}

.avatarSection {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.text {
  white-space: pre-wrap;
}

.redDot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: $red;
  display: inline-block;
  margin-bottom: 2.5px;
  margin-left: 8px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;

  h4 {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 26px;
  }
}

.dropdownMenu {
  background-color: $white;
  min-height: 250px;
  max-height: 600px;
  width: 360px;
  overflow-y: scroll;
  border-radius: 8px;
}
