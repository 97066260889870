@import "styles/colours.scss";

.bottomRight {
  position: fixed;
  right: 0;
  bottom: 0;
  top: initial;
  left: initial;

  margin: 0px 32px;
}

.noThanks {
  color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $white;
  }
}

.startTourBtn {
  color: $text-primary-v2;

  &:hover,
  &:focus,
  &:active {
    color: "#1A3369";
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.closeIcon {
  color: $white;
}

.allowBackgroundActivity {
  pointer-events: none;
}
