@import "styles/colours";

.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterChipsContainer {
  display: flex;
  column-gap: 3px;

  .filterChipLabel {
    color: $sysTextDefault;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .filterChip {
    padding: 3px 10px;
    border-radius: 16px;
    border: 1px solid $colorPrimary;
    margin-right: 0;
    background-color: $sysPrimarySubtle;

    &:hover {
      background-color: $sysPrimarySubtleAlt;
      border-color: $sysPrimaryHover;

      span {
        color: $sysPrimaryHover;
      }
    }
  }

  .closeIcon {
    color: $sysPrimaryPressed !important;
  }

  .closeIconDefault {
    display: inline;
    margin-left: 5px;
  }

  .closeIconHover {
    display: none;
    margin-left: 5px;
  }

  .filterChip .closeIconWrapper {
    position: relative;
  }

  .filterChip .closeIconWrapper:hover .closeIconDefault {
    display: none;
  }

  .filterChip .closeIconWrapper:hover .closeIconHover {
    display: inline;
  }
}

.filterBarText {
  color: $colorTextSecondary;
}

.filterBarItem {
  display: flex;
  column-gap: 5px;
  align-items: baseline;
}
