@import "styles/colours";

.option {
  padding: 0px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-height: 32px;
  max-height: 32px;
}

.optionlabel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.option:hover {
  background-color: $colorPrimaryBg;
  border-radius: 4px;
}

.signalIcon {
  margin-right: 4px;
  color: $colorPrimary;
}
